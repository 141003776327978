/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Field } from 'formik';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useGA4 } from '../../../../lib/hooks/useGA4';

import AbsoluteSpinner from '../../../common/AbsoluteSpinner';
import CreditCardAddForm from '../../../common/CreditCardAddForm/CreditCardAddForm';
import CreditCardList from '../../../common/CreditCardList';
import { OpenErrorNotification } from '../../../common/Toast/Toast';
import Checkbox from '../../../common/Checkbox';
import { couponValidity, planPaymentInfo } from '../../../../lib/tracking/eventGenerator';

import { ReactComponent as UploadFail } from '../../../../assets/UploadFail.svg';
import { ReactComponent as UploadOk } from '../../../../assets/UploadOk.svg';
import { ReactComponent as Dollar } from '../../../../assets/dollar.svg';
import { ReactComponent as Ticket } from '../../../../assets/ticket.svg';
import { ReactComponent as InfoCheckbox } from '../../../../assets/icons/info-checkbox.svg';

import { STRIPE_PUBLIC_KEY, STORE_TERMS_URL, CONTACT_US_URL } from '../../../../env';
import {
  CERTIFICATION_PLAN,
  CREATE_MAKER_MONTHLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_YEARLY,
  CLOUD_CLASSROOM,
  FREQUENCY_YEARLY,
  ENTERPRISE_PLAN_YEARLY,
  ENTERPRISE_PLAN_MONTHLY,
  JUNIOR_CERTIFICATION_PRODUCT,
} from '../../../../utils/utilities';

import './Payment.scss';

const FIELD_COUPON = 'coupon';
const FIELD_COUPON_INVALID_ERROR = 'Your coupon is invalid';
function FormWrapper(props) {
  const {
    match,
    coupon,
    history,
    setErrors,
    values,
    isValid,
    cloudPath,
    errors,
    verifyCoupon,
    cloudPlanType,
    certificationPath,
    enterprisePath,
    educationPath,
    setCouponOpened,
    enterprisePlanFrequency,
    certificationType,
  } = props;
  const [addDiscountCode, setAddDiscountCode] = useState(false);
  const trackCouponAddedEvent = useGA4(couponValidity);

  useEffect(() => {
    if (coupon.error && coupon.error !== null) {
      setErrors({ [FIELD_COUPON]: FIELD_COUPON_INVALID_ERROR });
      setCouponValid(false);
      // * Until now we have tracked down users who clubbed an invalid coupon code.
      // * Probabily in future we'll had to re-track this case, so keep this code commented.
      //trackCouponAddedEvent({ coupon: FIELD_COUPON, coupon_validity: 'no' });
    }
  }, [coupon.error]);

  useEffect(() => {
    if (values[FIELD_COUPON] && !coupon.inProgress) {
      if (coupon.data && coupon.data.valid && isValid) {
        setCouponValid(true);
        trackCouponAddedEvent({ coupon: FIELD_COUPON, coupon_validity: 'yes' });
      } else {
        setErrors({ [FIELD_COUPON]: FIELD_COUPON_INVALID_ERROR });
        setCouponValid(false);
      }
    }
  }, [coupon.inProgress]);

  useEffect(() => {
    if (values[FIELD_COUPON] && cloudPath) {
      delete errors[FIELD_COUPON];
      verifyCoupon(values[FIELD_COUPON], cloudPlanType);
    }
  }, [cloudPlanType]);

  const [couponValid, setCouponValid] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    props.setTouched({ [FIELD_COUPON]: true });
    props.validateForm();
    if (props.values[FIELD_COUPON] && props.cloudPath) {
      props.verifyCoupon(props.values[FIELD_COUPON], props.cloudPlanType);
    } else if (props.values[FIELD_COUPON] && educationPath) {
      props.verifyCoupon(props.values[FIELD_COUPON], CLOUD_CLASSROOM);
    } else if (props.values[FIELD_COUPON] && certificationPath && certificationType === CERTIFICATION_PLAN) {
      props.verifyCoupon(props.values[FIELD_COUPON], CERTIFICATION_PLAN);
    } else if (props.values[FIELD_COUPON] && certificationPath && certificationType === JUNIOR_CERTIFICATION_PRODUCT) {
      props.verifyCoupon(props.values[FIELD_COUPON], JUNIOR_CERTIFICATION_PRODUCT);
    } else if (props.values[FIELD_COUPON] && props.enterprisePath) {
      props.verifyCoupon(
        props.values[FIELD_COUPON],
        enterprisePlanFrequency === FREQUENCY_YEARLY ? ENTERPRISE_PLAN_YEARLY : ENTERPRISE_PLAN_MONTHLY
      );
    } else if (props.values[FIELD_COUPON]) {
      props.verifyCoupon(props.values[FIELD_COUPON], props.plan.couponId || props.plan.id);
    }
  };

  return (
    <>
      {((!props.isEduUpgrading && !props.isCloudUpgrading) || props.certificationPath || props.enterprisePath) && (
        <>
          {addDiscountCode ? (
            <div>
              <div className="main-text">Coupon code</div>
              <div className="form-fields" onClick={onSubmit}>
                <div className="form-group form-group-6-6" style={{ width: '100%' }}>
                  <div className="form-item">
                    <div
                      className={classnames(
                        'input-group',
                        { error: props.errors[FIELD_COUPON] },
                        { valid: couponValid },
                        { progress: props.coupon.inProgress }
                      )}
                    >
                      <Field
                        name={FIELD_COUPON}
                        type="text"
                        placeholder={'Coupon Code'}
                        className={classnames(
                          'coupon-input',
                          {
                            error:
                              (props?.coupon.data && !props.coupon?.data?.valid) ||
                              (props.errors[FIELD_COUPON] &&
                                props?.coupon.error !== null &&
                                props?.coupon.data === null),
                          },
                          { valid: couponValid }
                        )}
                      />
                      <div className="busy-spinner">
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div className="form-item">
                    <div className="apply-button-container">
                      <button className="apply-button" disabled={props.coupon.inProgress} type="submit">
                        {'Apply Code'}
                      </button>
                    </div>
                  </div>
                </div>
                {props.errors[FIELD_COUPON] &&
                  (!props.values?.coupon.includes('maker_plan_12months_off') ||
                    props.cloudPlanFrequency === 'monthly' ||
                    props.cloudPlanFrequency === '') && (
                    <div className="discount-error">
                      <UploadFail /> <span className="discount-text"> Your discount code is invalid</span>
                    </div>
                  )}
                {props.errors[FIELD_COUPON] &&
                  cloudPath &&
                  props.values?.coupon.includes('maker_plan_12months_off') &&
                  props.cloudPlanFrequency === 'yearly' && (
                    <div className="discount-error">
                      <UploadFail />{' '}
                      <span className="discount-text">
                        This code is valid only with monthly plans. Please update the billing frequency and try again.
                      </span>
                    </div>
                  )}
                {props.coupon?.data?.percent_off > 0 && props.coupon?.data?.valid && (
                  <div className="discount-message">
                    <UploadOk /> <span className="discount-text"> {props.coupon.data.percent_off}% discount code</span>
                  </div>
                )}
                {props.coupon?.data?.amount_off > 0 && props.coupon?.data?.valid && (
                  <div className="discount-message">
                    <UploadOk />
                    <span className="discount-text"> {props.coupon.data.amount_off / 100} $ discount code</span>
                  </div>
                )}
                {/* <div className="form-group form-group-6-6">
                  <div className="form-item">
                    <div className="apply-button-container">
                      <button className="apply-button" disabled={props.coupon.inProgress} type="submit">
                        {'Apply Code'}
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
              {(enterprisePath || educationPath) && (
                <div className="sub-text">
                  <InfoCheckbox className="sub-text__icon" /> Coupons can only be used when paying with credit card. For
                  other payment methods,
                  <a
                    className="link"
                    rel="noopener noreferrer"
                    onClick={() => history.push(`${match.path}/alternative-payment`)}
                  >
                    &nbsp;contact us
                  </a>
                </div>
              )}
            </div>
          ) : (
            // <button onClick={() => setAddDiscountCode(true)} className="discount-code">
            //   Add discount code
            // </button>
            <div
              className="coupon-container__box"
              onClick={() => {
                setAddDiscountCode(true);
                setCouponOpened(true);
              }}
              style={{ marginLeft: '0' }}
            >
              <div className="coupon-container__icon">
                <Ticket />
              </div>
              <div className="coupon-container__text--small">Have a coupon code?</div>
              <div className="billing-form-button">
                <button
                  className="delete-button"
                  onClick={() => {
                    setAddDiscountCode(true);
                    setCouponOpened(true);
                  }}
                >
                  {'USE COUPON'}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

FormWrapper.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  validateForm: PropTypes.func,
  verifyCoupon: PropTypes.func,
  isValid: PropTypes.bool,
  coupon: PropTypes.object,
  cart: PropTypes.object,
  setErrors: PropTypes.func,
  setTouched: PropTypes.func,
  plan: PropTypes.object.isRequired,
  isEduUpgrading: PropTypes.bool,
  isCloudUpgrading: PropTypes.bool,
  cloudPlanType: PropTypes.string,
  cloudPlanFrequency: PropTypes.string,
  cloudPath: PropTypes.bool,
  certificationPath: PropTypes.bool,
  enterprisePath: PropTypes.bool,
  setCouponOpened: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  enterprisePlanFrequency: PropTypes.string,
  certificationType: PropTypes.string,
};

function PaymentForm(props) {
  const {
    isEduUpgrading,
    educationPath,
    enterprisePath,
    coupon,
    enterprisePlanSelected,
    match,
    handlePaymentPageVisited,
  } = props;
  const [useNewCard, setUseNewCard] = useState(true);
  const [terms, setTerms] = useState({ value: false, showError: false });
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [addCardFormSubmit, setAddCardFormSubmit] = useState(null);
  const [alternativePayment, setAlternativePayment] = useState(false);

  useEffect(() => {
    handlePaymentPageVisited();
  }, []);

  const trackPaymentArrivesEvent = useGA4(planPaymentInfo);

  if (!props?.paymentTrackingSended) {
    props.handlePaymentTrackingSender();
    const formattedCloudPlanType =
      props.cloudPlanType === '' ||
      props.cloudPlanType === CREATE_MAKER_MONTHLY ||
      props.cloudPlanType === CREATE_MAKER_YEARLY
        ? 'maker'
        : props.cloudPlanType === CREATE_MAKERPLUS_MONTHLY || props.cloudPlanType === CREATE_MAKERPLUS_YEARLY
        ? 'maker plus'
        : 'entry';
    trackPaymentArrivesEvent({
      plan_type: educationPath ? 'school' : 'individuals',
      plan_level: educationPath ? '' : formattedCloudPlanType,
      billing_type: educationPath ? '' : props.cloudPlanFrequency,
    });
  }

  if (props.cloudPath && props.cloudPlanFrequency === '' && props.cloudPlanType === '') {
    props.history.push(match.path);
  }

  //don't work for certification
  const fullValidCoupon = useMemo(() => {
    if (props.coupon?.data?.percent_off === 100 && props.coupon?.data?.valid) return true;
    return false;
  }, [props.coupon?.data]);

  const handleTermsChange = (event) => {
    setTerms({
      value: event.target.checked,
      showError: !event.target.checked,
    });
  };

  const resetTerms = () => {
    setTerms({
      value: false,
      showError: false,
    });
    setAlternativePayment(true);
  };

  useEffect(() => {
    if (alternativePayment) props.history.push(`${match.path}/alternative-payment`);
  }, [alternativePayment]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!terms.value) {
      setTerms({
        value: false,
        showError: true,
      });
    }
    if (useNewCard) {
      // Just tell the Add Card Form to trigger its own stripe submit
      // once stripe will be done this.handleAddCreditCardSubmit will be called
      if (addCardFormSubmit) {
        addCardFormSubmit();
      }
    } else {
      if (selectedCreditCard) {
        if (!terms.value) {
          return;
        }
        props.onExistingCardSubmit(selectedCreditCard);
      }
    }
  };

  useEffect(() => {
    props.handlePaymentForm(props.actionInProgress, terms.value, fullValidCoupon, useNewCard);
  }, [props.actionInProgress, terms.value, fullValidCoupon, useNewCard]);

  const handleAddCreditCardSubmit = (token) => {
    if (!terms.value) {
      return;
    }
    props.onNewCardSubmit(token);
  };

  const handleCancel = (value) => {
    setUseNewCard(value);
  };

  const handleCreateCard = (token) => {
    props.createCard(token);
  };

  const handleUseNewCardChange = (useNewCard) => {
    setUseNewCard(useNewCard);
    setSelectedCreditCard(null);
  };

  const handleCreditCardSelectorChange = (selectedCreditCard) => {
    setSelectedCreditCard(selectedCreditCard);
    setUseNewCard(false);
  };

  const handleCreditCardListLoad = (cardId) => {
    setUseNewCard(false);
    setSelectedCreditCard(cardId);
  };

  useEffect(() => {
    if (props.card.delete.error) {
      OpenErrorNotification(
        'This card could not be deleted because it is attached to an active plan. To delete this card, you need to modify that plan’s payment information.'
      );
    }
  }, [props.card.delete.error]);

  useEffect(() => {
    if (Object.values(props.card.byId).length > 0) {
      setUseNewCard(false);
    } else {
      setUseNewCard(true);
    }
  }, [props.card.byId]);

  useEffect(() => {
    if (props.errorOnBuying !== null) {
      if (props.errorOnBuying.status) {
        switch (props.errorOnBuying.status) {
          case 400:
            OpenErrorNotification(props.errorOnBuying.body?.detail || props.errorOnBuying.message);
            break;

          case 500:
            OpenErrorNotification(
              <span>
                An error occurred while purchasing, please retry later. If the problem persist{' '}
                <a target="_blank" rel="noreferrer" href={CONTACT_US_URL}>
                  contact the support
                </a>
                .
              </span>
            );
            break;
          default:
            OpenErrorNotification(
              <span>
                An error occurred while purchasing, please retry later. If the problem persist{' '}
                <a target="_blank" rel="noreferrer" href={CONTACT_US_URL}>
                  contact the support
                </a>
                .
              </span>
            );
        }
      }
    }
  }, [props.errorOnBuying]);

  useEffect(() => {
    if (props.errorOnCreateCard !== null) {
      OpenErrorNotification('Your card is incorrect. Please check your Expiration Date and security code.');
    }
  }, [props.errorOnCreateCard]);

  let creditCardSelection = null;
  const [couponOpened, setCouponOpened] = useState(false);

  if (Object.values(props.card.byId).length > 0) {
    creditCardSelection = (
      <CreditCardList
        onChange={handleCreditCardSelectorChange}
        onLoad={handleCreditCardListLoad}
        useNewCard={useNewCard}
      />
    );
  }

  if (props.card.readList.inProgress || props.card.delete.inProgress || props.card.create.inProgress) {
    return (
      <div className="payment-spinner-wrapper">
        <AbsoluteSpinner height={'100%'} clouds />
      </div>
    );
  }

  //Redirect to begin of checkout if refresh (only if the user is in broken state)
  if (enterprisePath && !enterprisePlanSelected) {
    return <Redirect push to={match.path} />;
  }

  let errorTermsMessage = null;

  if (terms.showError) {
    errorTermsMessage = (
      <span className="error-message">{'You need to accept the terms and conditions to proceed'}</span>
    );
  }

  const form = (
    <Formik
      initialValues={{
        [FIELD_COUPON]: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <FormWrapper
          verifyCoupon={props.verifyCoupon}
          coupon={props.coupon}
          cart={props.cart}
          plan={props.plan}
          isEduUpgrading={props.isEduUpgrading}
          isCloudUpgrading={props.isCloudUpgrading}
          certificationPath={props.certificationPath}
          enterprisePath={props.enterprisePath}
          educationPath={props.educationPath}
          cloudPath={props.cloudPath}
          cloudPlanType={props.cloudPlanType}
          cloudPlanFrequency={props.cloudPlanFrequency}
          setCouponOpened={setCouponOpened}
          history={props.history}
          match={props.match}
          enterprisePlanFrequency={props.enterprisePlanFrequency}
          certificationType={props.certificationType}
          {...formikProps}
        />
      )}
    </Formik>
  );

  return (
    <div className={classnames('arduino-form purchase-payment ')}>
      <form id="payment-form" className={classnames('form-fields')} onSubmit={handleSubmit}>
        {creditCardSelection}
        <div className={classnames('use-new-card-section', { expanded: useNewCard === true })}>
          <div
            className={classnames('use-new-card-form', {
              visible: useNewCard === true,
              'no-cards': Object.values(props.card.byId).length === 0,
            })}
          >
            <div className="header-panel">
              <label className="ardu-radio">
                <input
                  type="radio"
                  name="use-new-card"
                  onChange={() => handleUseNewCardChange(true)}
                  checked={useNewCard === true}
                />
                <span className="custom-radio"></span>
                <h4>{'Add new Card'}</h4>
              </label>
              <div className="use-new-card" onClick={() => handleUseNewCardChange(true)}>
                <h4>{'Add new Card'}</h4>
              </div>
            </div>
            <CreditCardAddForm
              stripePublicKey={STRIPE_PUBLIC_KEY}
              onSubmit={handleAddCreditCardSubmit}
              onCancel={() => handleCancel(false)}
              addSubmitHook={(fn) => setAddCardFormSubmit(() => fn)}
              onCreateCard={(stripeToken) => handleCreateCard(stripeToken)}
              firstPayment={false}
              enterprisePath={enterprisePath}
              educationPath={educationPath}
              isEduUpgrading={isEduUpgrading}
              coupon={coupon}
            />
          </div>
        </div>
        <hr className="hr-style" />
        <div className="coupon-container">
          {/* Discount Code */}
          <div className={classnames('coupon-container__form', { 'coupon-container__form--opened': couponOpened })}>
            {form}
          </div>
          {/* Alternative payment method */}

          {(educationPath || enterprisePath) && (
            <div
              className={classnames('coupon-container__box', { 'coupon-container__box--hided': couponOpened })}
              onClick={() => resetTerms()}
            >
              <div className="coupon-container__icon">
                <Dollar />
              </div>
              <div className="coupon-container__text">Looking for other payment methods?</div>
              <div className="billing-form-button">
                <button className="delete-button" onClick={() => resetTerms()}>
                  {'Contact Us'}
                </button>
              </div>
            </div>
          )}
        </div>

        <hr className="hr-style" />
        <div className="terms-wrapper">
          <div className="checkbox-wrapper">
            <Checkbox
              name="terms"
              checkboxLabel={
                <span>
                  {'I have read and accepted the '}
                  <a
                    className="link-button terms-link"
                    href={`${STORE_TERMS_URL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Terms and Conditions'}
                  </a>
                </span>
              }
              checked={terms.value}
              onChange={handleTermsChange}
              className={`left ${terms.showError ? 'error' : ''}`}
            />
            {errorTermsMessage}
          </div>
        </div>
      </form>
      {/* Discount Code */}
      {/* <div className="arduino-form">{form}</div> */}
    </div>
  );
}

PaymentForm.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  card: PropTypes.object,
  stripe: PropTypes.object,
  onNewCardSubmit: PropTypes.func.isRequired,
  onExistingCardSubmit: PropTypes.func.isRequired,
  actionInProgress: PropTypes.bool,
  errorOnBuying: PropTypes.object,
  errorOnCreateCard: PropTypes.any,
  createCard: PropTypes.func,
  cart: PropTypes.object,
  isEduUpgrading: PropTypes.bool,
  isCloudUpgrading: PropTypes.bool,
  cloudPlanType: PropTypes.string,
  cloudPlanFrequency: PropTypes.string,
  certificationPath: PropTypes.bool,
  enterprisePath: PropTypes.bool,
  educationPath: PropTypes.bool,
  cloudPath: PropTypes.bool,
  verifyCoupon: PropTypes.func,
  coupon: PropTypes.object,
  plan: PropTypes.object,
  handlePaymentForm: PropTypes.func,
  handleNewCardValidated: PropTypes.func,
  enterprisePlanSelected: PropTypes.bool,
  handlePaymentPageVisited: PropTypes.func,
  enterprisePlanFrequency: PropTypes.string,
  certificationType: PropTypes.string,
};

export default PaymentForm;
