import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import CreditCardInfo from '../CreditCardInfo';
import DeleteCardModal from './DeleteCardModal';
import './CreditCardList.scss';

const CreditCardList = function CreditCardList(props) {
  const { card, onLoad } = props;
  useEffect(() => {
    const defaultCard = Object.values(card.byId).find((card) => card.default === true)?.id;
    if (sessionStorage.getItem('newCard') !== null) {
      card.list.forEach((card) => {
        if (card.id === sessionStorage.getItem('newCard')) {
          const defaultCard = card.id;
          setSelectedCard(defaultCard);
          onLoad(defaultCard);
        }
      });
      sessionStorage.removeItem('newCard');
    } else if (defaultCard) {
      setSelectedCard(defaultCard);
      onLoad(defaultCard);
    } else if (card.list.length > 0) {
      const defaultCard = card.list[0].id;
      setSelectedCard(defaultCard);
      onLoad(defaultCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const [selectedCard, setSelectedCard] = useState({});

  const onSelectedCardChange = (cardId) => {
    setSelectedCard(cardId);
    props.onChange(cardId);
  };

  // States for the delete subscription modal
  const [cardToDelete, setCardToDelete] = useState('');

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalShow = function handleCancelModalShow(cardId) {
    setCardToDelete(cardId);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalHide = function handleCancelModalHide() {
    setCardToDelete('');
  };

  const handleConfirmDeleteCard = function handleConfirmDeleteCard() {
    deleteCard(cardToDelete);
  };

  const deleteCard = (cardId) => {
    setCardToDelete('');
    props.deleteCard(cardId);
  };

  const options = Object.values(props.card.byId).map((card, index) => {
    const expirationDate = moment(`${card.exp_year}-${card.exp_month}`, 'YYYY-MM').endOf('month');
    const isExpired = expirationDate.isBefore(moment(), 'day');
    return (
      <div
        className={classnames('credit-card-list-element', {
          selected: selectedCard === card.id && !props.useNewCard,
          disabled: isExpired,
        })}
        key={index}
        onClick={() => onSelectedCardChange(card.id)}
        onChange={() => onSelectedCardChange(card.id)}
      >
        <label className="ardu-radio">
          <input
            type="radio"
            name="use-new-card"
            checked={selectedCard === card.id && !props.useNewCard && !isExpired}
            onClick={() => onSelectedCardChange(card.id)}
            onChange={() => onSelectedCardChange(card.id)}
            disabled={isExpired}
          />
          <span className="custom-radio"></span>
        </label>
        <CreditCardInfo
          card={card}
          icon
          lastFour
          clickable
          onClick={() => onSelectedCardChange(card.id)}
          isExpired={isExpired}
        />
        <button type="button" className="delete-button" onClick={() => handleDeleteModalShow(card.id)}>
          {'Delete'}
        </button>
      </div>
    );
  });

  return (
    <>
      <div className="credit-card-list">{options}</div>
      {deleteModalOpen && (
        <DeleteCardModal
          open={cardToDelete !== '' && deleteModalOpen}
          busy={props.card.delete.inProgress}
          onConfirmCancelSubscription={handleConfirmDeleteCard}
          onToggle={handleDeleteModalHide}
        />
      )}
    </>
  );
};

CreditCardList.propTypes = {
  card: PropTypes.object,
  onChange: PropTypes.func,
  onLoad: PropTypes.func,
  deleteCard: PropTypes.func,
  useNewCard: PropTypes.bool,
};

export default CreditCardList;
