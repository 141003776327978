import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { STRIPE_PUBLIC_KEY, STORE_TERMS_URL, CONTACT_US_URL } from '../../../../env';
import AbsoluteSpinner from '../../../common/AbsoluteSpinner';
import Checkbox from '../../../common/Checkbox';
import CreditCardAddForm from '../../../common/CreditCardAddForm/CreditCardAddForm';
import CreditCardList from '../../../common/CreditCardList';
import { OpenErrorNotification } from '../../../common/Toast/Toast';

function PaymentForm(props) {
  const [useNewCard, setUseNewCard] = useState(true);
  const [terms, setTerms] = useState({ value: false, showError: false });
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [addCardFormSubmit, setAddCardFormSubmit] = useState(null);

  const handleTermsChange = (event) => {
    setTerms({
      value: event.target.checked,
      showError: !event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!terms.value) {
      setTerms({
        value: false,
        showError: true,
      });
    }

    if (useNewCard) {
      // Just tell the Add Card Form to trigger its own stripe submit
      // once stripe will be done this.handleAddCreditCardSubmit will be called
      if (addCardFormSubmit) {
        addCardFormSubmit();
      }
    } else {
      if (selectedCreditCard) {
        if (!terms.value) {
          return;
        }
        props.onExistingCardSubmit(selectedCreditCard);
      }
    }
  };

  const handleAddCreditCardSubmit = (token) => {
    if (!terms.value) {
      return;
    }
    props.onNewCardSubmit(token);
  };

  const handleUseNewCardChange = (useNewCard) => {
    setUseNewCard(useNewCard);
    setSelectedCreditCard(null);
  };

  const handleCreditCardSelectorChange = (selectedCreditCard) => {
    setSelectedCreditCard(selectedCreditCard);
    setUseNewCard(false);
  };

  const handleCreditCardListLoad = (cardId) => {
    setUseNewCard(false);
    setSelectedCreditCard(cardId);
  };

  const handleCreateCard = (token) => {
    props.createCard(token);
  };

  useEffect(() => {
    if (props.card.delete.error) {
      OpenErrorNotification(
        'This card could not be deleted because it is attached to an active plan. To delete this card, you need to modify that plan’s payment information.'
      );
    }
  }, [props.card.delete.error]);

  useEffect(() => {
    if (Object.values(props.card.byId).length > 0) {
      setUseNewCard(false);
    } else {
      setUseNewCard(true);
    }
  }, [props.card.byId]);

  useEffect(() => {
    if (props.errorOnBuying !== null) {
      if (props.errorOnBuying.status) {
        switch (props.errorOnBuying.status) {
          case 400:
            OpenErrorNotification(props.errorOnBuying.body?.detail || props.errorOnBuying.message);
            break;
          case 500:
            OpenErrorNotification(
              <span>
                An error occurred while purchasing, please retry later. If the problem persist{' '}
                <a target="_blank" rel="noreferrer" href={CONTACT_US_URL}>
                  contact the support
                </a>
                .
              </span>
            );
            break;
          default:
            OpenErrorNotification(
              <span>
                An error occurred while purchasing, please retry later. If the problem persist{' '}
                <a target="_blank" rel="noreferrer" href={CONTACT_US_URL}>
                  contact the support
                </a>
                .
              </span>
            );
        }
      }
    }
  }, [props.errorOnBuying]);

  let creditCardSelection = null;

  if (Object.values(props.card.byId).length > 0) {
    creditCardSelection = (
      <CreditCardList
        onChange={handleCreditCardSelectorChange}
        onLoad={handleCreditCardListLoad}
        useNewCard={useNewCard}
      />
    );
  }

  if (props.card.readList.inProgress || props.card.delete.inProgress) {
    return (
      <div className="payment-spinner-wrapper">
        <AbsoluteSpinner height={'100%'} clouds />
      </div>
    );
  }

  let errorTermsMessage = null;

  if (terms.showError) {
    errorTermsMessage = (
      <span className="error-message">{'You need to accept the terms and conditions to proceed'}</span>
    );
  }

  return (
    <div className={classnames('arduino-form purchase-payment ')}>
      <form className={classnames('form-fields')} onSubmit={handleSubmit}>
        {creditCardSelection}
        <div className={classnames('use-new-card-section', { expanded: useNewCard === true })}>
          <div
            className={classnames('use-new-card-form', {
              visible: useNewCard === true,
              'no-cards': Object.values(props.card.byId).length === 0,
            })}
          >
            <div className="header-panel">
              <label className="ardu-radio">
                <input
                  type="radio"
                  name="use-new-card"
                  onChange={() => handleUseNewCardChange(true)}
                  checked={useNewCard === true}
                />
                <span className="custom-radio"></span>
                <h4>{'Add new Card'}</h4>
              </label>
              <div className="use-new-card" onClick={() => handleUseNewCardChange(true)}>
                <h4>{'Add new Card'}</h4>
              </div>
            </div>
            <CreditCardAddForm
              stripePublicKey={STRIPE_PUBLIC_KEY}
              onSubmit={handleAddCreditCardSubmit}
              addSubmitHook={(fn) => setAddCardFormSubmit(() => fn)}
              onCreateCard={(stripeToken) => handleCreateCard(stripeToken)}
              firstPayment={true}
            />
          </div>
        </div>
        <div className="terms-wrapper">
          <div className="checkbox-wrapper">
            <Checkbox
              name="terms"
              checkboxLabel={
                <span>
                  {'I have read and accepted the '}
                  <a
                    className="link-button terms-link"
                    href={`${STORE_TERMS_URL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'Terms and Conditions'}
                  </a>
                </span>
              }
              checked={terms.value}
              onChange={handleTermsChange}
              className={`left ${terms.showError ? 'error' : ''}`}
            />
            {errorTermsMessage}
          </div>
        </div>
        <div className="pay-button-container">
          <button
            className={classnames('pay-button', { busy: props.actionInProgress })}
            disabled={props.actionInProgress}
            type="submit"
          >
            <span className="ardu-store-button-text">{'Done'}</span>
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

PaymentForm.propTypes = {
  card: PropTypes.object,
  stripe: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onNewCardSubmit: PropTypes.func.isRequired,
  onExistingCardSubmit: PropTypes.func.isRequired,
  actionInProgress: PropTypes.bool,
  errorOnBuying: PropTypes.object,
};

export default PaymentForm;
