import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './Aws.scss';
import { ReactComponent as Plus } from '../../../assets/plansConfigurationIcons/Plus.svg';
import moment from 'moment';
import { Button, Input, Select } from '@arduino/arc';
import classnames from 'classnames';
import { OpenErrorSnackbar } from '../../common/Toast/Toast';
import { ENTERPRISE_AWS_PRODUCT, OTHER, SPACE_ENTERPRISE_PLAN, SPACE_PRIVATE_PLAN } from '../../../utils/utilities';
import NotCompatibleComponent from '../subscriptions/activations/NotCompatibleComponent';
import { CLOUD_HOME } from '../../../env';

const isEduPlan = false;

const AwsRedeemComponent = (props) => {
  const {
    readOrganizationList,
    readCountries,
    createOrganization,
    country,
    organizations,
    createAwsSubscription,
    profile,
  } = props;
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [onLoading, setOnLoading] = useState(false);
  const [onCreateOrganizationLoading, setOnCreateOrganizationLoading] = useState(false);
  const [isNewOrganization, setIsNewOrganization] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [focused, setFocused] = useState(false);

  const quantity = localStorage.getItem('quantity');

  const getValidQuantity = useCallback(() => {
    const parsedQuantity = parseInt(quantity, 10);

    if (Number.isInteger(parsedQuantity)) {
      return parsedQuantity;
    }

    return 0;
  }, [quantity]);

  const role = useMemo(() => {
    return localStorage.getItem('plan_id') !== ENTERPRISE_AWS_PRODUCT ? SPACE_PRIVATE_PLAN : SPACE_ENTERPRISE_PLAN;
  }, []);

  const formattedCountryOption = country.countriesList.map((e) => {
    return { label: e.name, value: e.code };
  });

  const confirmDisabled = useMemo(() => {
    if (role === SPACE_PRIVATE_PLAN) return false;
    if (
      (!selectedOrganization && newOrganizationName === '') ||
      onCreateOrganizationLoading ||
      (isNewOrganization && (selectedCountry === '' || newOrganizationName === '')) ||
      getValidQuantity() === 0
    )
      return true;
    return false;
  }, [
    getValidQuantity,
    isNewOrganization,
    newOrganizationName,
    onCreateOrganizationLoading,
    role,
    selectedCountry,
    selectedOrganization,
  ]);

  const createNewOrganization = useCallback(async () => {
    setOnCreateOrganizationLoading(true);
    const organization = {
      name: newOrganizationName,
      type: OTHER,
      country: selectedCountry,
      space: SPACE_ENTERPRISE_PLAN,
    };
    createOrganization(organization);
    setTimeout(() => {
      readOrganizationList();
      setOnCreateOrganizationLoading(false);
      setTimeout(() => {
        setSelectedOrganization(true);
      }, 2000);
    }, 1000);
  }, [createOrganization, newOrganizationName, readOrganizationList, selectedCountry]);

  const handleCreateSubscription = useCallback(async () => {
    try {
      setOnLoading(true);
      await createAwsSubscription({
        awsAccountId: localStorage.getItem('aws_account_id'),
        awsCustomerId: localStorage.getItem('aws_customer_id'),
        productCode: localStorage.getItem('product_code'),
        organizationId: selectedOrganization.id,
        planId: localStorage.getItem('plan_id'),
        quantity: Number(localStorage.getItem('quantity')),
        expirationDate: localStorage.getItem('expiration_date'),
        duration: Number(localStorage.getItem('aws_duration')),
        awsToken: decodeURIComponent(localStorage.getItem('aws_token')),
      });

      setTimeout(() => {
        if (localStorage.getItem('error_message')) {
          OpenErrorSnackbar(localStorage.getItem('error_message'));
          localStorage.removeItem('error_message');
          setOnLoading(false);
        } else {
          setTimeout(() => {
            localStorage.removeItem('aws_account_id');
            localStorage.removeItem('aws_customer_id');
            localStorage.removeItem('product_code');
            localStorage.removeItem('plan_id');
            localStorage.removeItem('quantity');
            localStorage.removeItem('expiration_date');
            localStorage.removeItem('aws_duration');
            localStorage.removeItem('aws_token');
            window.location.replace(`${CLOUD_HOME}?join=${selectedOrganization.id}`);
          }, 2000);
        }
      }, 1000);
      //}
    } catch (e) {
      OpenErrorSnackbar(e.body.message);
    }
  }, [createAwsSubscription, selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && isNewOrganization) {
      const choosenOrganization = organizations.organizations.find((org) => org.name === newOrganizationName);
      setSelectedOrganization(choosenOrganization);
      setOnCreateOrganizationLoading(false);
      setIsNewOrganization(false);
    }
  }, [selectedOrganization, organizations, newOrganizationName, isNewOrganization, createAwsSubscription]);

  function handleNewOrganization() {
    setSelectedOrganization(null);
    setIsNewOrganization(true);
    if (!focused) {
      setFocused(true);
      setTimeout(() => {
        document.getElementById('create-button').focus();
      }, 100);
    }
  }

  function handleOrganization(organization) {
    if (!isOrganizationDisabled(organization)) {
      setSelectedOrganization(organization);
    }
    setIsNewOrganization(false);
  }

  function isOrganizationDisabled(organization) {
    return organization.plan_id !== '';
  }

  useEffect(() => {
    readOrganizationList();
    readCountries();
  }, [isNewOrganization, readCountries, readOrganizationList]);

  return (
    <section className="aws-page__redeem">
      <div className="aws-page__organizations">
        <div className="aws-page__organizations__title">Assign this plan</div>
        {role === SPACE_PRIVATE_PLAN && (
          <div className={classnames('organization__wrapper')}>
            <div className="organization__wrapper--left">
              <label className="ardu-radio">
                <input type="radio" name="use-new-organization" checked={true} />
                <span className="custom-radio"></span>
              </label>
            </div>
            <div className="organization__wrapper--right">
              <h5 className="organization__title">{profile?.data?.contact?.email}</h5>
              <div className="organization__members">Private Space</div>
            </div>
          </div>
        )}
        <div className="aws-page__wrapper">
          {role === SPACE_ENTERPRISE_PLAN &&
            organizations?.organizations
              ?.filter((institution) => institution.space === role)
              .map((organization) => {
                return (
                  <div
                    className={classnames('organization__wrapper', {
                      'organization--disabled': isOrganizationDisabled(organization),
                    })}
                    key={organization.id}
                    onClick={() => handleOrganization(organization)}
                  >
                    <div className="organization__wrapper--left">
                      <label className="ardu-radio">
                        <input
                          disabled={isOrganizationDisabled(organization)}
                          type="radio"
                          name="use-new-organization"
                          onChange={() => handleOrganization(organization)}
                          onClick={() => handleOrganization(organization)}
                          checked={
                            selectedOrganization?.id === organization.id && !isOrganizationDisabled(organization)
                          }
                        />
                        <span className="custom-radio"></span>
                      </label>
                    </div>
                    <div className="organization__wrapper--right">
                      <h5 className="organization__title">
                        {organization.name}
                        {isOrganizationDisabled(organization) && (
                          <NotCompatibleComponent
                            popupText={'This Shared Space has already a Cloud Plan associated.'}
                          />
                        )}
                      </h5>
                      <div className="organization__members">{organization.members} members</div>
                    </div>
                  </div>
                );
              })}
          {!isNewOrganization && role === SPACE_ENTERPRISE_PLAN ? (
            <div
              className={'organization__wrapper organization__create'}
              onClick={() => (!isEduPlan ? handleNewOrganization() : null)}
            >
              <div className="organization__wrapper--left">
                <Plus
                  style={{
                    marginTop: '5px',
                    filter: isEduPlan ? 'opacity(50%)' : 'opacity(100%)',
                  }}
                />
              </div>
              <div className="organization__wrapper--right">
                Set up a new Shared Space
                {isEduPlan && <NotCompatibleComponent popupText={'You cannot create an organization for this plan.'} />}
              </div>
            </div>
          ) : isNewOrganization && role === SPACE_ENTERPRISE_PLAN ? (
            <div
              className="organization__wrapper organization__wrapper--reverse"
              onClick={() => handleNewOrganization()}
            >
              <div className="organization__wrapper--left">
                <div className="organization__checkbox">
                  <label className="ardu-radio">
                    {organizations?.organizations.filter((institution) => institution.space === role).length > 0 && (
                      <>
                        <input
                          type="radio"
                          name="use-new-organization"
                          onChange={() => handleNewOrganization()}
                          onClick={() => handleNewOrganization()}
                          checked={selectedOrganization === null}
                        />
                        <span className="custom-radio"></span>
                      </>
                    )}
                    <span>Set up a new Shared Space</span>
                  </label>
                </div>
              </div>
              <Input
                className="custom-margin"
                label="Organization name"
                value={newOrganizationName}
                name={'Organization Name'}
                onChange={(event) => setNewOrganizationName(event.target.value)}
                required
              />
              <Select
                className="select-custom-margin"
                label="Country"
                placeholder="Select your country"
                name={'Country'}
                value={country}
                options={formattedCountryOption}
                onChange={(value) => setSelectedCountry(value as string)}
                isRequired
              />
              <Button
                className="aws-page__button button-create"
                isDisabled={onCreateOrganizationLoading || !isNewOrganization || newOrganizationName === ''}
                onPress={() => createNewOrganization()}
                loading={onCreateOrganizationLoading}
              >
                CREATE
              </Button>
              <button id="create-button" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="aws-page__summary">
        <div>
          <div className="aws-page__summary__title">Plan summary</div>
          <div className="aws-page__summary__description">
            {role === SPACE_ENTERPRISE_PLAN ? 'Cloud Enterprise' : 'Cloud Maker'}
          </div>
          <hr />
          <div className="aws-page__summary__data">
            <div className="aws-page__info">
              <span>Quantity</span>
              <span>{getValidQuantity()} Things</span>
            </div>
            <div className="aws-page__info">
              <span>Expiration date</span>
              <span>{moment(localStorage.getItem('expiration_date')).format('MMMM, DD YYYY')}</span>
            </div>
          </div>
        </div>
        <div>
          <Button
            className="aws-page__button button"
            isDisabled={confirmDisabled}
            onPress={() => handleCreateSubscription()}
            loading={onLoading}
          >
            CONFIRM
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AwsRedeemComponent;
