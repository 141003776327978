/* eslint-disable no-prototype-builtins */
import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { OpenErrorNotification } from '../../common/Toast/Toast';

import Payment from './Payment';
import PaymentInformation from './PaymentInformation';
import AlternativePayment from './AlternativePayment';
import ProductDetails from './ProductDetails';
import { TrackWithGA4 } from '../../../lib/hooks/useGA4';
import AbsoluteSpinner from '../../common/AbsoluteSpinner';
import BillingInfoForm from '../../common/BillingInfoForm';
import EduPlanConfiguration from '../../common/EduPlanConfiguration/EduPlanConfiguration';
import CheckoutMaintenance from '../../common/CheckoutMaintenance/CheckoutMaintenance';
import CloudPlanConfiguration from '../../common/CloudPlanConfiguration/CloudPlanConfiguration';
import CertificationPlanConfiguration from '../../common/CertificationPlanConfiguration/CertificationPlanConfiguration';
import CertificationExamTypeConfiguration from '../../common/CertificationExamTypeConfiguration/CertificationExamTypeConfiguration';
import EnterprisePlanConfiguration from '../../common/EnterprisePlanConfiguration/EnterprisePlanConfiguration';
import InstitutionConfiguration from '../../common/InstitutionConfiguration/InstitutionConfiguration';
import EducationBreadcrumbs from '../../common/PurchaseBreadcrumbs/EducationBreadcrumbs';
import CloudBreadcrumbs from '../../common/PurchaseBreadcrumbs/CloudBreadcrumbs';
import CertificationBreadcrumbs from '../../common/PurchaseBreadcrumbs/CertificationBreadcrumbs';
import EnterpriseBreadcrumbs from '../../common/PurchaseBreadcrumbs/EnterpriseBreadcrumbs';
import EnterpriseAddonsConfiguration from '../../common/EnterpriseAddonsConfiguration/EnterpriseAddonsConfiguration';
import { currencyCountries } from '../../../currencyCountries';
import {
  getUpgradePlanComplete,
  planPurchase,
  planDowngrade,
  planBeginCheckout,
  planBillingInfo,
  eduPlanConfiguration,
} from '../../../lib/tracking/eventGenerator';
import { CREATE_BASE_URL, APP_URL, STRIPE_PUBLIC_KEY, CLOUD_HOME, CHECKOUT_MAINTENANCE } from '../../../env';
import {
  ENTERPRISE_PURCHASE,
  EDUCATION_PURCHASE,
  EDUCATION_PURCHASE_INSTITUTION,
  CLOUD_CLASSROOM,
  OTHER,
  CERTIFICATION_PLAN,
  EDUCATION_PURCHASE_PLAN,
  CLOUD_PURCHASE,
  CLOUD_CREATE,
  CREATE_FREE,
  CERTIFICATION_PURCHASE,
  CERTIFICATION_STARTERKIT,
  CREATE_MAKER_MONTHLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_ENTRY_YEARLY,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_YEARLY,
  ENTERPRISE_PRODUCT,
  ENTERPRISE_PLAN_MONTHLY,
  ENTERPRISE_PLAN_YEARLY,
  FREQUENCY_MONTHLY,
  SPACE_ENTERPRISE_PLAN,
  SPACE_EDUCATION_PLAN,
  DEFAULT_COMPANY,
  JUNIOR_CERTIFICATION_PRODUCT,
} from '../../../utils/utilities';

import './PurchaseComponent.scss';

/*
 * /purchase                      --> cloud plans checkout
 * /education/purchase            --> education plans checkout
 * /certification/purchase        --> certification checkout AND junior certification checkout
 * /enterprise/purchase           --> pro plans checkout
 */

//const WrappedSticky = StickyWindowDimension(Sticky);

/*
 * Next refactoring:
 * - delete products details and use a single components
 * - remove functions for change params and use a single new function
 */

const getQuantityNumber = (quantity) => {
  return isNaN(parseInt(quantity)) ? 1 : parseInt(quantity);
};

class Purchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: props.subscription.byId[props.subscription.byPlan[CLOUD_CLASSROOM]]?.quantity || 5,
      organizationId: null,
      firstChargeQuantity: false,
      lastTotalCalculates: false,
      purchaseInProgress: false,
      newOrganization: false,
      billingOrganization: {
        name: '',
        country: '',
      },

      certificationType: 'cert_fundamentals',
      billingTrackingSended: false,
      paymentTrackingSended: false,
      upgradingOrganization: '',
      planUpdated: false,
      queryStringOrganization: '',
      loading: true,
      cloudPlanFrequency: '',
      cloudPlanType: '',
      currentCloudSubscription: '',
      isCloudDowngrading: false,
      examCodes: 1,
      juniorExamCodes: 1,
      enterprisePlanThings: 100,
      enterprisePlanSelected: false,
      enterprisePlanFrequency: '',
      enterprisePlanAddons: [],
      enterpriseAddonsPrice: 0,
      enterpriseOrganizationCreated: false,
      enterpriseSpaceId: null,
      x8DevicesNumber: 0,
      infiniteSpinner: false,

      infiniteCertificationSpinner: false,

      //CTA
      eduInstitutionCtaDisabled: false,
      enterpriseConfigurePlanDisabled: false,
      billingDisabled: false,
      paymentDisabled: false,
      paymentProgress: false,
      terms: false,
      fullValidCoupon: false,
      cloudPlanDisabled: false,
      paymentInfoDisabled: false,

      //Error
      cardDeclined: false,
      newCard: false,

      //Breadcrumbs
      institutionPageVisited: false,
      examTypePageVisited: false,
      selectPlanPageVisited: false,
      addonsPlanPageVisited: false,
      billingPageVisited: false,
      paymentPageVisited: false,

      //Zapier price
      price: 0,

      // datalayer event data
      finalPrice: 0,
    };

    this.handleInstitutionSubmit = this.handleInstitutionSubmit.bind(this);
    this.handlePlanSubmit = this.handlePlanSubmit.bind(this);
    this.handleBillingSubmit = this.handleBillingSubmit.bind(this);
    this.handleEditBillingInfo = this.handleEditBillingInfo.bind(this);
    this.handlePaymentSubmit = this.handlePaymentSubmit.bind(this);
    this.handleCertificationSubmit = this.handleCertificationSubmit.bind(this);
    this.handleJuniorCertificationSubmit = this.handleJuniorCertificationSubmit.bind(this);
    this.handleEnterpriseSubmit = this.handleEnterpriseSubmit.bind(this);
    this.handleEducationAlternativePaymentSubmit = this.handleEducationAlternativePaymentSubmit.bind(this);
    this.handleEnterpriseAlternativePaymentSubmit = this.handleEnterpriseAlternativePaymentSubmit.bind(this);
    this.handlePaymentBack = this.handlePaymentBack.bind(this);
    this.handleSelectedCountry = this.handleSelectedCountry.bind(this);
    this.handleSelectedState = this.handleSelectedState.bind(this);
    this.getPlanTrial = this.getPlanTrial.bind(this);
    this.handleSetOrganization = this.handleSetOrganization.bind(this);
    this.handleCreateOrganization = this.handleCreateOrganization.bind(this);
    this.changeQuantityOfCurrentInstitution = this.changeQuantityOfCurrentInstitution.bind(this);
    this.handleCreateCard = this.handleCreateCard.bind(this);
    this.handleQueryStringPlan = this.handleQueryStringPlan.bind(this);
    this.handleOrganizationSelected = this.handleOrganizationSelected.bind(this);
    this.handleUpdateCloudPlanFrequency = this.handleUpdateCloudPlanFrequency.bind(this);
    this.handleUpdateCloudPlanName = this.handleUpdateCloudPlanName.bind(this);
    this.handleChangeCloudDowngrading = this.handleChangeCloudDowngrading.bind(this);
    this.handleUpdateEnterprisePlanFrequency = this.handleUpdateEnterprisePlanFrequency.bind(this);
    this.handleUpdateEnterprisePlanThings = this.handleUpdateEnterprisePlanThings.bind(this);
    this.handleUpdateEnterpriseX8Devices = this.handleUpdateEnterpriseX8Devices.bind(this);
    this.handlePaymentTrackingSender = this.handlePaymentTrackingSender.bind(this);
    this.formatCloudPlanType = this.formatCloudPlanType.bind(this);
    this.handlePaymentPageVisited = this.handlePaymentPageVisited.bind(this);

    //CTA - buttons
    this.handleEduInstitutionCta = this.handleEduInstitutionCta.bind(this);
    this.handleEduConfigurePlanCta = this.handleEduConfigurePlanCta.bind(this);
    this.handleEnterpriseConfigurePlanCta = this.handleEnterpriseConfigurePlanCta.bind(this);
    this.handleBillingDisabled = this.handleBillingDisabled.bind(this);
    this.handlePaymentForm = this.handlePaymentForm.bind(this);
    this.handlePaymentTerms = this.handlePaymentTerms.bind(this);
    this.handleCloudPlanDisabled = this.handleCloudPlanDisabled.bind(this);
    this.handlePaymentInfoDisabled = this.handlePaymentInfoDisabled.bind(this);

    // datalayer events data
    this.setFinalPrice = this.setFinalPrice.bind(this);
  }

  setFinalPrice(price) {
    this.setState({ finalPrice: price });
  }

  getPlanTrial() {
    const plan = this.props.plan.plans[this.props.cart.plan];
    const educationPath = this.props.match.path === EDUCATION_PURCHASE;
    const cloudPath = this.props.match.path === CLOUD_PURCHASE;
    const certificationPath = this.props.match.path === CERTIFICATION_PURCHASE;
    const enterprisePath = this.props.match.path === ENTERPRISE_PURCHASE;
    // Check free trial status for all subscription plans
    if (!plan) {
      this.props.unsetCart();
    }
    if (plan && plan.type === 'subscription') {
      const planTrialParams = this.props.cart.iccid
        ? {
            iccid: this.props.cart.iccid,
          }
        : null;

      if (educationPath) {
        this.props.getPlanTrial(CLOUD_CLASSROOM, planTrialParams);
      } else if (cloudPath) {
        this.props.getPlanTrial(this.props.cart.plan, planTrialParams);
      } else if (certificationPath) {
        this.props.getPlanTrial(CLOUD_CLASSROOM, planTrialParams);
      } else if (enterprisePath) {
        this.props.getPlanTrial(CLOUD_CLASSROOM, planTrialParams);
      }
    }
  }

  componentDidMount() {
    localStorage.removeItem('path');
    const educationPath = this.props.match.path === EDUCATION_PURCHASE;
    const cloudPath = this.props.match.path === CLOUD_PURCHASE;
    const certificationPath = this.props.match.path === CERTIFICATION_PURCHASE;
    const enterprisePath = this.props.match.path === ENTERPRISE_PURCHASE;
    sessionStorage.removeItem('billingTrackingSended');
    const enterpriseSpaceId = localStorage.getItem('enterpriseSpaceId');
    if (enterpriseSpaceId !== 'null') {
      this.setState({ enterpriseSpaceId: enterpriseSpaceId });
    }

    if (this.props.cart.plan) {
      this.getPlanTrial();
    } else {
      const cart = JSON.parse(sessionStorage.getItem('digital_cart'));
      if (cart && cart.plan) {
        this.props.setCart(cart.product, cart.plan, cart.iccid, cart.quantity, cart.price !== null ? cart.price : 0);
      } else {
        if (educationPath) return this.props.history.push('/purchase-redirect/cloud_classroom/cloud_classroom');
        else if (cloudPath) this.props.setCart('create', 'create_free', null, 1, 1);
        else if (certificationPath)
          this.props.setCart('certifications_starterkit_bulk', 'certifications_starterkit_bulk', null, 1, 1);
        else if (enterprisePath) this.props.setCart('cloud_enterprise', 'cloud_enterprise_monthly', null, 1, 1);
      }
    }
    this.props.readSubscriptionList();
    this.props.readCardList();
    this.props.readCountries();
    this.props.readOrganizationList();
    this.props.createSubscriptionClearError();
    this.props.readAvailableCountriesList();
    this.props.clearCoupon();
    this.props.clearPlanTrial();
    this.props.clearChargeError();
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  @TrackWithGA4(getUpgradePlanComplete)
  trackDowngrade(props) {
    const plan = props.plan.plans[props.cart.plan];
    return {
      item_name: plan.fullName,
      item_id: plan.id,
      price: props.taxes?.data?.amount,
      taxes: props.taxes?.data?.taxes,
      total: props.taxes?.data?.taxed_amount,
      coupon: props.coupon.data && props.coupon.data.valid ? props.coupon.data.id : null,
    };
  }

  @TrackWithGA4(planDowngrade)
  planDowngrade(data) {
    return {
      transaction_id: data.transaction_id,
      value: data.value,
      tax: data.tax,
      currency: data.currency,
      plan_type: data.plan_type,
      plan_level: data.plan_level,
      billing_type: data.billing_type,
    };
  }

  @TrackWithGA4(planPurchase)
  planPurchase(data) {
    return {
      transaction_id: data.transaction_id,
      value: data.value,
      tax: data.tax,
      currency: data.currency,
      plan_type: data.plan_type,
      plan_level: data.plan_level,
      billing_type: data.billing_type,
      is_update: data.is_update,
    };
  }

  @TrackWithGA4(eduPlanConfiguration)
  eduPlanConfiguration() {
    return {
      plan_type: 'school',
    };
  }

  @TrackWithGA4(planBeginCheckout)
  planBeginCheckout(data) {
    return {
      plan_type: data.plan_type,
      plan_level: data.plan_level,
      billing_type: data.billing_type,
    };
  }

  @TrackWithGA4(planBillingInfo)
  planBillingInfo(data) {
    return {
      plan_type: data.plan_type,
      plan_level: data.plan_level,
      billing_type: data.billing_type,
    };
  }

  componentDidUpdate = (prevProps) => {
    const educationPath = this.props.match.path === EDUCATION_PURCHASE;
    const cloudPath = this.props.match.path === CLOUD_PURCHASE;
    const certificationPath = this.props.match.path === CERTIFICATION_PURCHASE;
    const params = new URLSearchParams(window.location.search);

    if (
      !this.state.cardDeclined &&
      this.props.charge.create.error !== null &&
      this.props.charge.create.error.body.detail
    ) {
      OpenErrorNotification(this.props.charge.create.error.body.detail);
      this.setState({ cardDeclined: true });
    }

    if (params.get('organization_id') !== null && this.state.queryStringOrganization === '') {
      this.setState({
        queryStringOrganization: params.get('organization_id'),
        organizationId: localStorage.getItem('organization'),
      });
    } else if (localStorage.getItem('organization') !== null && this.state.queryStringOrganization === '') {
      this.setState({
        queryStringOrganization: localStorage.getItem('organization'),
        organizationId: localStorage.getItem('organization'),
      });
    }

    if (EDUCATION_PURCHASE_INSTITUTION === this.props.location.pathname && this.state.planUpdated) {
      this.setState({ planUpdated: false });
    }

    //Set current cloud subscription
    if (
      this.props.subscription.byId[this.props.subscription.byProduct[CLOUD_CREATE]] &&
      this.state.currentCloudSubscription === ''
    ) {
      this.setState({
        currentCloudSubscription: this.props.subscription.byId[this.props.subscription.byProduct[CLOUD_CREATE]],
      });
    }

    //Use this for calculate total price if upgrading for CLOUD plans.
    const isCloudUpgrading = this.state.currentCloudSubscription.plan !== CREATE_FREE && cloudPath;
    if (
      !this.state.lastTotalCalculates &&
      cloudPath &&
      isCloudUpgrading &&
      this.state.currentCloudSubscription !== ''
    ) {
      this.props.readUpgradePreview(this.state.currentCloudSubscription.id, this.state.cloudPlanType, 1);
      this.setState({ lastTotalCalculates: true });
    }

    //Set selected cloud subscription from cloud Cloud WebSite
    if (
      localStorage.getItem('cloudPlanName') !== 'null' &&
      localStorage.getItem('cloudPlanFrequency') !== 'null' &&
      this.state.cloudPlanFrequency === '' &&
      this.state.cloudPlanType === ''
    ) {
      this.setState({
        cloudPlanFrequency: localStorage.getItem('cloudPlanFrequency'),
        cloudPlanType:
          'create_' + localStorage.getItem('cloudPlanName') + '_' + localStorage.getItem('cloudPlanFrequency'),
      });
      this.planBeginCheckout({
        plan_type: 'individuals',
        plan_level:
          localStorage.getItem('cloudPlanName') === 'makerplus' ? 'maker plus' : localStorage.getItem('cloudPlanName'),
        billing_type: localStorage.getItem('cloudPlanFrequency'),
      });
    }

    //Set selected Enterprise frequency from Cloud WebSite
    if (localStorage.getItem('enterprisePlanFrequency') !== null && this.state.enterprisePlanFrequency === '') {
      this.setState({
        enterprisePlanFrequency: localStorage.getItem('enterprisePlanFrequency'),
      });
      localStorage.removeItem('enterprisePlanFrequency');
    }

    let anOrganizationhasPlan = false;
    let isEduUpgrading = false;
    this.props?.organizations?.organizations.forEach((organization) => {
      if (organization.plan_id !== '' && organization.id === this.state.organizationId) anOrganizationhasPlan = true;
      //Reset quantity when we change organization selected
      if (
        organization.id === this.state.organizationId &&
        this.state.quantity !== organization.plan_seats &&
        organization.plan_seats !== 0 &&
        this.props.location.pathname === EDUCATION_PURCHASE_INSTITUTION
      ) {
        this.setState({ quantity: organization.plan_seats });
      } else if (
        organization.id === this.state.organizationId &&
        this.state.quantity !== 5 &&
        organization.plan_seats === 0 &&
        this.props.location.pathname === EDUCATION_PURCHASE_INSTITUTION
      ) {
        this.setState({ quantity: 5 });
      }
    });
    if (!this.state.newOrganization && anOrganizationhasPlan) isEduUpgrading = true;

    // Redirect to the thank you page after a subscription has successfully created
    if (
      !educationPath &&
      cloudPath &&
      ((prevProps.subscription.create.inProgress && !this.props.subscription.create.inProgress) ||
        (prevProps.subscription.upgrade.inProgress && !this.props.subscription.upgrade.inProgress))
    ) {
      if (!this.props.subscription.create.error && !this.props.subscription.upgrade.error) {
        this.trackDowngrade(this.props);
        this.props.unsetCart();
        this.props.history.push(`/${this.props.cart.product}/purchase-completed`);
      }
    } else if (
      educationPath &&
      ((prevProps.subscription.create.inProgress && !this.props.subscription.create.inProgress) ||
        (prevProps.subscription.upgrade.inProgress && !this.props.subscription.upgrade.inProgress))
    ) {
      this.trackDowngrade(this.props);
      this.props.unsetCart();

      //this.props.history.push(`${APP_URL}/purchase-completed`);
    } else if (
      certificationPath &&
      ((prevProps.subscription.create.inProgress && !this.props.subscription.create.inProgress) ||
        (prevProps.subscription.upgrade.inProgress && !this.props.subscription.upgrade.inProgress))
    ) {
      this.props.unsetCart();
      this.props.history.push(`/create/purchase-completed`);
    }

    if (!educationPath && prevProps.charge.create.inProgress && !this.props.charge.create.inProgress) {
      // Redirect to the thank you page after a charge has successfully created
      //const plan = this.props.plan.plans[this.props.cart.plan];
      if (!this.props.charge.create.error) {
        this.props.unsetCart();
        // this.props.history.push(
        //   `/${this.props.cart.product}/purchase-completed${plan.type === 'multiPurchase' ? '/multi' : ''}`
        // );
      } else {
        this.setState({
          errorToastMessage: 'An unexpected error has occurred. Your purchase has not been completed.',
        });
      }
    }

    if (prevProps.cart.plan !== this.props.cart.plan) {
      if (this.props.cart.plan) {
        this.getPlanTrial();
      }
    }

    let planSubscription = this.props.subscription.byId[this.props.subscription.byPlan[CLOUD_CLASSROOM]];
    for (const key in this.props.subscription.byId) {
      if (this.props.subscription.byId.hasOwnProperty(key)) {
        if (
          this.props.subscription.byId[key].plan === CLOUD_CLASSROOM &&
          this.props.subscription.byId[key].organization_id === this.state.organizationId
        ) {
          planSubscription = this.props.subscription.byId[key];
        }
      }
    }

    //Set quantity for organizations
    if (
      planSubscription?.quantity &&
      this.state.quantity !== planSubscription?.quantity &&
      !this.state.firstChargeQuantity
    ) {
      this.props.readSubscriptionList();
      this.setState({
        firstChargeQuantity: true,
        quantity: this.state.quantity,
      });
    }

    //Use this for calculate total price if upgrading for School (Edu) plans.
    if (planSubscription?.quantity && !this.state.lastTotalCalculates && isEduUpgrading) {
      this.props.readUpgradePreview(
        planSubscription?.id,
        CLOUD_CLASSROOM,
        planSubscription?.quantity,
        this.props.coupon?.data?.id || null
      );
      this.setState({ lastTotalCalculates: true });
    }
  };

  resetCoupon() {
    if (this.props.coupon.data) {
      this.props.verifyCoupon(null, 'cloud');
      OpenErrorNotification('Your discount code has been removed');
      delete this.props.coupon.error;
      this.setState({ deleteCouponBanner: true });
      setTimeout(() => {
        delete this.props.coupon.error;
      }, 4000);
    }
  }

  //CTA - button
  handleEduInstitutionCta(value) {
    if (value !== this.state.eduInstitutionCtaDisabled) this.setState({ eduInstitutionCtaDisabled: value });
  }

  handleEduConfigurePlanCta(value) {
    if (value !== this.state.eduConfigurePlanDisabled) this.setState({ eduConfigurePlanDisabled: value });
  }

  handleEnterpriseConfigurePlanCta(value) {
    if (value !== this.state.enterpriseConfigurePlanDisabled) this.setState({ enterpriseConfigurePlanDisabled: value });
  }

  handleBillingDisabled(value) {
    if (value !== this.state.billingDisabled) this.setState({ billingDisabled: value });
  }

  handlePaymentForm(paymentProgress, terms, fullValidCoupon, newCard) {
    if (paymentProgress !== this.state.paymentProgress) this.setState({ paymentProgress: paymentProgress });
    if (terms !== this.state.terms) this.setState({ terms: terms });
    if (fullValidCoupon !== this.state.fullValidCoupon) this.setState({ fullValidCoupon: fullValidCoupon });
    if (newCard !== this.state.newCard) this.setState({ newCard: newCard });
  }

  handlePaymentTerms(terms) {
    if (terms !== this.state.terms) this.setState({ terms: terms });
  }

  handleCloudPlanDisabled(value) {
    if (value !== this.state.cloudPlanDisabled) this.setState({ cloudPlanDisabled: value });
  }

  handlePaymentInfoDisabled(value) {
    if (value !== this.state.paymentInfoDisabled) this.setState({ paymentInfoDisabled: value });
  }

  //End CTA - button

  resetFlow() {
    this.setState({
      examTypePageVisited: false,
      selectPlanPageVisited: false,
      billingPageVisited: false,
      paymentPageVisited: false,
    });
  }

  handleCertificationExamTypeSubmit(certification) {
    this.setState({ certificationType: certification.type });
    this.setState({ examTypePageVisited: true, selectPlanPageVisited: true });
    this.props.history.push(`${this.props.match.path}/plan`);
  }

  handleCertificationBillingSubmit() {
    this.props.history.push(`${this.props.match.path}/billing`);

    this.setState({ selectPlanPageVisited: true, billingPageVisited: true });
  }

  handleChangeCloudDowngrading(value) {
    if (this.state.isCloudDowngrading !== value) this.setState({ isCloudDowngrading: value });
  }

  handleCreateCard(stripeToken) {
    this.props.createCard(stripeToken.token);
  }

  handleBillingEdit(value) {
    this.setState({ billingEdit: value });
  }

  handlePaymentTrackingSender() {
    this.setState({ paymentTrackingSended: true });
  }

  //This is used for add new card in payment component.
  submitUpdateCard = (stripeToken) => {
    this.props.updateSubscriptionCard(this.props.subscription.id, null, stripeToken.token.id);
  };

  getUpgradePreview(subscriptionId, planName, quantity) {
    this.props.readUpgradePreview(subscriptionId, planName, quantity, this.props.coupon?.data?.id || null);
  }

  changeQuantityOfCurrentInstitution(value) {
    this.setState({ quantity: value });
  }

  handleInstitutionSubmit(values) {
    this.setState({ institutionPageVisited: true, selectPlanPageVisited: true });
    if (
      values.institution_name &&
      values.name !== '' &&
      values.type !== '' &&
      values.country !== '' &&
      !this.props.charge.create.inProgress
    ) {
      this.props.readOrganizationList();
      this.setState({
        newOrganization: true,
        organizationId: values.institution_name,
        billingOrganization: {
          name: values.institution_name,
          country: values.country,
        },
      });
      this.eduPlanConfiguration();
      this.props.history.push(`${this.props.match.path}/plan`);
    } else if (this.state.organizationId !== '' && this.props.organizations.count > 0) {
      this.setState({
        organizationId: this.state.organizationId,
        billingOrganization: {
          name: this.state.organizationId,
          country: null,
        },
      });
      this.props.readOrganizationList();
      this.eduPlanConfiguration();
      this.props.history.push(`${this.props.match.path}/plan`);
    } else if (this.state.organizationId !== '' && this.props.organizations.count === 0) {
      this.props.readOrganizationList();
      this.setState({
        newOrganization: true,
        organizationId: values.institution_name,
        billingOrganization: {
          name: values.institution_name,
          country: values.country,
        },
      });
      this.eduPlanConfiguration();
      this.props.history.push(`${this.props.match.path}/plan`);
    }
    this.props.readOrganizationList();
  }

  handlePlanSubmit() {
    let anOrganizationhasPlan = false;
    let isEduUpgrading = false;
    this.props.organizations.organizations.forEach((organization) => {
      if (organization.plan_id !== '' && organization.id === this.state.organizationId) anOrganizationhasPlan = true;
    });
    if (!this.state.newOrganization && anOrganizationhasPlan) isEduUpgrading = true;

    if (isEduUpgrading) this.props.history.push(`${this.props.match.path}/payment-info`);
    else this.props.history.push(`${this.props.match.path}/billing`);

    this.setState({ selectPlanPageVisited: true, billingPageVisited: true });
  }

  handleCloudPlanSubmit(data) {
    const cloudPath = this.props.match.path === CLOUD_PURCHASE;
    const isCloudUpgrading = this.state.currentCloudSubscription.plan !== CREATE_FREE && cloudPath;
    this.setState({ cloudPlanFrequency: data.frequency, cloudPlanType: data.type });
    this.setState({ paymentTrackingSended: false });
    if (isCloudUpgrading) this.props.history.push(`${this.props.match.path}/payment-info`);
    else this.props.history.push(`${this.props.match.path}/billing`);

    this.setState({ selectPlanPageVisited: true, billingPageVisited: true });
  }

  handleEnterprisePlanSubmit(data) {
    this.setState({
      enterprisePlanFrequency: data.frequency,
      enterprisePlanThings: data.thing,
      enterprisePlanSelected: true,
    });
    this.props.history.push(`${this.props.match.path}/addons`);

    this.setState({ selectPlanPageVisited: true, addonsPlanPageVisited: true });
  }

  handleEnterpriseAddonsSubmit() {
    this.props.history.push(`${this.props.match.path}/billing`);

    this.setState({ billingPageVisited: true });
  }

  handleUpdateEnterpriseAddons(data) {
    const found = this.state.enterprisePlanAddons.filter((addon) => addon === data);
    let stateUpdated = [];
    if (found.length > 0) {
      for (let i = 0; i < this.state.enterprisePlanAddons.length; i++) {
        if (this.state.enterprisePlanAddons[i] === data) {
          stateUpdated = this.state.enterprisePlanAddons.filter((addon) => addon !== data);
        }
      }
      this.setState({ enterprisePlanAddons: stateUpdated });
    } else {
      this.setState({ enterprisePlanAddons: [...this.state.enterprisePlanAddons, data] });
    }
    if (data.includes('cloud_enterprise_board_manager')) this.setState({ x8DevicesNumber: 50 });
  }

  formatCloudPlanType(cloudType) {
    switch (cloudType) {
      case '':
        return 'maker';
      case CREATE_MAKER_MONTHLY:
        return 'maker';
      case CREATE_MAKERPLUS_MONTHLY:
        return 'maker plus';
      case CREATE_ENTRY_YEARLY:
        return 'entry';
      case CREATE_MAKER_YEARLY:
        return 'maker';
      case CREATE_MAKERPLUS_YEARLY:
        return 'maker plus';
    }
  }

  handleUpdateCloudPlanFrequency(frequency) {
    this.setState({ cloudPlanFrequency: frequency });
    this.planBeginCheckout({
      plan_type: 'individuals',
      plan_level: this.formatCloudPlanType(this.state.cloudPlanType),
      billing_type: frequency,
    });
  }

  handleUpdateCloudPlanName(name) {
    this.setState({ cloudPlanType: name });
    this.resetCoupon();
  }

  handleUpdateEnterprisePlanFrequency(frequency) {
    if (this.state.enterprisePlanFrequency !== frequency) {
      this.setState({ enterprisePlanAddons: [] });
      this.setState({ enterprisePlanFrequency: frequency });
    }
  }

  handleUpdateEnterprisePlanThings(things) {
    if (things <= this.state.enterprisePlanThings) {
      this.setState({ enterprisePlanAddons: [] });
      this.setState({ selectPlanPageVisited: false });
      this.setState({ addonsPlanPageVisited: false });
      this.setState({ billingPageVisited: false });
      this.setState({ paymentPageVisited: false });
    }
    this.setState({ enterprisePlanThings: things });
    this.resetCoupon();
  }

  handleUpdateEnterpriseX8Devices(devicesNumber) {
    this.setState({ x8DevicesNumber: devicesNumber });
  }

  handlePaymentPageVisited() {
    this.setState({ paymentPageVisited: true });
  }

  handleBillingSubmit(values, { setSubmitting }) {
    const billingValues = { name: values.firstName.concat(' ').concat(values.lastName), ...values };
    delete billingValues.firstName;
    delete billingValues.lastName;

    if (billingValues.country !== 'US') {
      billingValues.state = billingValues.region;
      delete billingValues.tax_certificate;
    }

    if (currencyCountries.euCountries.findIndex((code) => code === billingValues.country) === -1) {
      billingValues.vat = '';
    }

    if (billingValues.type === 'P') {
      billingValues.company = '';
      billingValues.vat = '';
      delete billingValues.tax_certificate;
    }

    this.props.updateBillingInfo(billingValues);
    setSubmitting(false);
  }

  handleEditBillingInfo(values) {
    let firstBillingInfo = false;
    if (values.firstName) {
      if (this.props.profile.data.billing.name === '') {
        firstBillingInfo = true;
      }
      const billingValues = { name: values.firstName.concat(' ').concat(values.lastName), ...values };
      delete billingValues.firstName;
      delete billingValues.lastName;

      if (billingValues.country !== 'US') {
        billingValues.state = billingValues.region;
        delete billingValues.tax_certificate;
      }

      if (
        values.country !== 'IN' &&
        currencyCountries.euCountries.findIndex((code) => code === billingValues.country) === -1
      ) {
        billingValues.vat = '';
      }

      if (billingValues.type === 'P') {
        billingValues.company = '';
        billingValues.vat = '';
        delete billingValues.tax_certificate;
      }

      this.props.updateBillingInfo(billingValues);
    }

    if (firstBillingInfo) {
      this.props.history.push(`${this.props.match.path}/payment`);
    }
  }

  handlePaymentSubmit(submitData, newCard) {
    this.setState({ purchaseInProgress: true });
    const plan = this.props.plan.plans[this.props.cart.plan];
    const prorationDate = this.props.subscription?.lastTotalUpgradePrice?.prorationdate;

    const cloudPath = this.props.match.path === CLOUD_PURCHASE;
    const isCloudUpgrading = this.state.currentCloudSubscription.plan !== CREATE_FREE && cloudPath;

    if (plan.type === 'subscription') {
      const params = {
        coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
        iccid: null,
        // For organization, unused now
        organization_id: null,
        // The product plan id
        plan: this.state.cloudPlanType !== '' ? this.state.cloudPlanType : plan.id,
        // The product id
        product: CLOUD_CREATE,
        // unused for cloud plan
        quantity: 1,
        terms_accepted: true,
      };

      if (newCard) {
        // the existing credit card id
        params['default_source'] = null;
        // The stripe token id, only for new credit cards
        params['stripe_token'] = submitData.token.id;
      } else {
        // the existing credit card id
        params['default_source'] = submitData;
        // The stripe token id, only for new credit cards
        params['stripe_token'] = null;
      }

      // If buying the same product with different plan call upgrade subscription ( sub_default is the default one that need to be excluded)
      if (
        this.props.subscription.byProduct[this.props.cart.product] &&
        this.props.subscription.byProduct[this.props.cart.product] !== 'sub_default' &&
        this.props.plan.plans[params.plan].unique &&
        isCloudUpgrading
      ) {
        if (this.props.plan.plans[params.plan].unique) {
          //This for upgrade plan
          this.props.upgradeSubscription(
            this.props.subscription.byProduct[this.props.cart.product],
            params.plan,
            params.quantity,
            prorationDate
          );

          if (this.state.isCloudDowngrading) {
            let planLevel = '';
            if (this.state.cloudPlanType === CREATE_ENTRY_YEARLY) planLevel = 'entry';
            else if (this.state.cloudPlanType === CREATE_MAKER_MONTHLY || CREATE_MAKER_YEARLY) planLevel = 'maker';
            else if (this.state.cloudPlanType === CREATE_MAKERPLUS_MONTHLY || CREATE_MAKERPLUS_YEARLY)
              planLevel = 'maker plus';
            this.planDowngrade({
              plan_detail: '',
              value: this.state.finalPrice,
              tax: this.props?.taxes?.data?.taxes ?? 0,
              currency: 'USD',
              plan_type: 'individuals',
              plan_level: planLevel,
              billing_type: this.state.cloudPlanFrequency,
            });
          }
        } else {
          this.props.createSubscription(params);
        }
      } else {
        this.setState({ purchaseInProgress: false });
        this.props.createSubscription(params);
      }
      //Need to calcolate types of plan LEVEL
      let planLevel = '';
      if (this.state.cloudPlanType === CREATE_ENTRY_YEARLY) planLevel === 'entry';
      else if (this.state.cloudPlanType === CREATE_MAKER_MONTHLY || CREATE_MAKER_YEARLY) planLevel = 'maker';
      else if (this.state.cloudPlanType === CREATE_MAKERPLUS_MONTHLY || CREATE_MAKERPLUS_YEARLY)
        planLevel = 'maker plus';
      this.planPurchase({
        plan_detail: '',
        value: this.state.finalPrice,
        tax: this.props?.taxes?.data?.taxes || 0,
        currency: 'USD',
        plan_type: 'individuals',
        plan_level: planLevel,
        billing_type: this.state.cloudPlanFrequency,
        is_update: this.state.isCloudUpgrading,
      });
    }
  }

  handleCertificationSubmit(submitData, newCard) {
    this.setState({ purchaseInProgress: true });
    const params = {
      coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
      // The product id
      product:
        this.state.certificationType === CERTIFICATION_PLAN ? CERTIFICATION_STARTERKIT : JUNIOR_CERTIFICATION_PRODUCT,
      quantity: this.state.examCodes,
    };

    if (newCard) {
      // the existing credit card id
      params['default_source'] = null;
      // The stripe token id, only for new credit cards
      params['stripe_token'] = submitData.token.id;
    } else {
      // the existing credit card id
      params['default_source'] = submitData;
      // The stripe token id, only for new credit cards
      params['stripe_token'] = null;
    }

    this.setState({ infiniteCertificationSpinner: true });
    this.setState({ purchaseInProgress: false });
    this.props.createCharge(params);
    setTimeout(() => {
      if (this.props.charge.create.error === null && this.state.certificationType === CERTIFICATION_PLAN) {
        window.location.replace(`${APP_URL}/create/certification/thank-you`);
      } else if (
        this.props.charge.create.error === null &&
        this.state.certificationType === JUNIOR_CERTIFICATION_PRODUCT
      ) {
        window.location.replace(`${APP_URL}/create/junior-certification/thank-you`);
      } else {
        this.setState({ infiniteCertificationSpinner: false });
      }
    }, 6000);
  }

  handleJuniorCertificationSubmit(submitData, newCard) {
    this.setState({ purchaseInProgress: true });
    const params = {
      coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
      // The product id
      product: JUNIOR_CERTIFICATION_PRODUCT,
      quantity: this.state.juniorExamCodes,
    };

    if (newCard) {
      // the existing credit card id
      params['default_source'] = null;
      // The stripe token id, only for new credit cards
      params['stripe_token'] = submitData.token.id;
    } else {
      // the existing credit card id
      params['default_source'] = submitData;
      // The stripe token id, only for new credit cards
      params['stripe_token'] = null;
    }

    this.setState({ infiniteCertificationSpinner: true });
    this.setState({ purchaseInProgress: false });
    this.props.createCharge(params);
    setTimeout(() => {
      if (this.props.charge.create.error === null)
        window.location.replace(`${APP_URL}/create/junior-certification/thank-you`);
      else {
        this.setState({ infiniteCertificationSpinner: false });
      }
    }, 6000);
  }

  handleEnterpriseSubmit(submitData, newCard) {
    this.setState({ purchaseInProgress: true });

    let integrations = [];
    if (this.state.enterprisePlanAddons.length > 0) {
      for (let i = 0; this.state.enterprisePlanAddons.length > i; i++) {
        if (this.state.enterprisePlanAddons[i].includes('cloud_enterprise_board_manager')) {
          integrations.push({
            addon_id: this.state.enterprisePlanAddons[i],
            quantity: this.state.x8DevicesNumber,
          });
        }
      }
    }

    const params = {
      coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
      iccid: null,
      // The product plan id
      plan: this.state.enterprisePlanFrequency === FREQUENCY_MONTHLY ? ENTERPRISE_PLAN_MONTHLY : ENTERPRISE_PLAN_YEARLY,
      // The product id
      product: ENTERPRISE_PRODUCT,
      quantity: this.state.enterprisePlanThings,
      organization_id: null,
      terms_accepted: true,
      //addons: this.state.enterprisePlanAddons,
      addons: integrations,
    };

    if (newCard && params.coupon === null) {
      // the existing credit card id
      params['default_source'] = null;
      // The stripe token id, only for new credit cards
      params['stripe_token'] = submitData.token.id;
    } else if (params.coupon === null) {
      // the existing credit card id
      params['default_source'] = submitData;
      // The stripe token id, only for new credit cards
      params['stripe_token'] = null;
    }
    // If buying the same product with different plan call upgrade subscription ( sub_default is the default one that need to be excluded )
    if (!(this.props.subscription.create.error || this.props.subscription.create.inProgress)) {
      const organization = {
        name:
          this.props.billing.billingInfo.company !== ''
            ? this.props.billing.billingInfo.company
            : `${DEFAULT_COMPANY}-${Math.floor(Math.random() * 1000)}`, // random 3-digit number
        type: OTHER,
        country:
          this.props.billing.billingInfo.country !== ''
            ? this.props.billing.billingInfo.country
            : this.props.profile.data.billing.country,
        space: SPACE_ENTERPRISE_PLAN,
      };

      //Avoid to create multiple organization using state
      if (!this.state.enterpriseOrganizationCreated && !this.state.enterpriseSpaceId) {
        this.props.createOrganization(organization);
        this.setState({ enterpriseOrganizationCreated: true });
      }

      setTimeout(() => {
        this.props.readOrganizationList();
      }, 3000);
      //I have to find new organizations for create subscription
      setTimeout(() => {
        if (!this.state.enterpriseSpaceId) {
          this.props.organizations.organizations.forEach((org) => {
            if (org.name === organization.name) {
              params['organization_id'] = org.id;
            }
          });
        } else {
          params['organization_id'] = this.state.enterpriseSpaceId;
        }

        this.setState({ purchaseInProgress: false });
        if (params['organization_id'] !== null) {
          this.props.createSubscription(params);
          this.planPurchase({
            plan_detail: '',
            value: this.state.finalPrice,
            tax: this.props?.taxes?.data?.taxes || 0,
            currency: 'USD',
            plan_type: 'business',
            plan_level: '',
            billing_type: this.state.enterprisePlanFrequency,
            is_update: false,
          });
        } else {
          OpenErrorNotification("Something goes Wrong - can't create organization");
        }
        this.setState({ infiniteSpinner: true });
      }, 5000);

      setTimeout(() => {
        const queryParam = this.state.enterpriseSpaceId
          ? `renewal_id=${params['organization_id']}`
          : `space_id=${params['organization_id']}`;

        if (this.props.subscription.create.error === null && params['organization_id'] !== null)
          window.location.replace(`${CLOUD_HOME}?${queryParam}`);
      }, 15000);
    }
  }

  handleEnterpriseAlternativePaymentSubmit() {
    this.setState({ purchaseInProgress: true });

    let integrations = [];
    if (this.state.enterprisePlanAddons.length > 0) {
      for (let i = 0; this.state.enterprisePlanAddons.length > i; i++) {
        if (this.state.enterprisePlanAddons[i].includes('cloud_enterprise_board_manager')) {
          integrations.push({
            addon_id: this.state.enterprisePlanAddons[i],
            quantity: this.state.x8DevicesNumber,
          });
        }
      }
    }

    const params = {
      coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
      iccid: null,
      // The product plan id
      plan: this.state.enterprisePlanFrequency === FREQUENCY_MONTHLY ? ENTERPRISE_PLAN_MONTHLY : ENTERPRISE_PLAN_YEARLY,
      // The product id
      product: ENTERPRISE_PRODUCT,
      quantity: this.state.enterprisePlanThings,
      organization_id: null,
      terms_accepted: true,
      //addons: integrations,
      x8Addon:
        this.state.enterprisePlanAddons[0]?.includes('cloud_enterprise_board_manager') ||
        this.state.enterprisePlanAddons[1]?.includes('cloud_enterprise_board_manager')
          ? `Portenta X8 Board Manager (${this.state.x8DevicesNumber} things)`
          : null,
      x8AddonQuantity: this.state.enterprisePlanAddons[0] ? this.state.x8DevicesNumber : null,
      customerEmail: this.props.profile.data.contact.email ?? '',
      frequency: this.state.enterprisePlanFrequency,
      price: this.props.taxes.data?.taxed_amount ?? this.state.price,
    };

    this.props.createRequest(params);

    setTimeout(() => {
      window.location.replace(`${APP_URL}/create/inquiry/thank-you`);
    }, 3000);
  }

  handleEducationAlternativePaymentSubmit() {
    this.setState({ purchaseInProgress: true });

    let newOrg = '';
    this.props.organizations.organizations.forEach((organization) => {
      if (this.state.newOrganization && organization.name === this.state.organizationId) {
        newOrg = organization.id;
        this.setState({
          organizationId: organization.id,
        });
      }
    });
    const params = {
      coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
      iccid: null,
      // For organization, unused now
      organization_id: this.state.newOrganization ? newOrg : this.state.organizationId,
      // The product plan id
      plan: CLOUD_CLASSROOM, //plan.id,
      // The product id
      product: CLOUD_CLASSROOM, //this.props.cart.product,
      // Number of seats
      quantity: this.state.quantity,
      terms_accepted: true,
      customerEmail: this.props.profile.data.contact.email ?? '',
      frequency: this.state.enterprisePlanFrequency,
      price: this.props.taxes.data?.taxed_amount ?? this.state.price,
    };

    this.props.createRequest(params);

    setTimeout(() => {
      window.location.replace(`${APP_URL}/create/inquiry/thank-you`);
    }, 3000);
  }

  handleCertificationNumber(values) {
    this.setState({ examCodes: values });
  }

  handleJuniorCertificationNumber(values) {
    this.setState({ juniorExamCodes: values });
  }

  handleClassroomPaymentSubmit(submitData, newCard) {
    this.setState({ purchaseInProgress: true });
    this.props.readSubscriptionList();
    const plan = this.props.plan.plans[CLOUD_CLASSROOM];
    const prorationDate = this.props.subscription?.lastTotalUpgradePrice?.prorationdate;

    let planSubscription = this.props.subscription.byId[this.props.subscription.byPlan[CLOUD_CLASSROOM]];
    for (const key in this.props.subscription.byId) {
      if (this.props.subscription.byId.hasOwnProperty(key)) {
        if (
          this.props.subscription.byId[key].plan === CLOUD_CLASSROOM &&
          this.props.subscription.byId[key].organization_id === this.state.organizationId
        ) {
          planSubscription = this.props.subscription.byId[key];
        }
      }
    }

    let anOrganizationhasPlan = false;
    let isEduUpgrading = false;
    let newOrg = '';
    setTimeout(() => {
      this.props.organizations.organizations.forEach((organization) => {
        if (organization.plan_id !== '' && organization.id === this.state.organizationId) anOrganizationhasPlan = true;
        if (this.state.newOrganization && organization.name === this.state.organizationId) {
          newOrg = organization.id;
          this.setState({
            organizationId: organization.id,
          });
        }
      });
      if (!this.state.newOrganization && anOrganizationhasPlan) isEduUpgrading = true;

      if (plan?.type === 'subscription') {
        const params = {
          coupon: this.props.coupon.data && this.props.coupon.data.valid ? this.props.coupon.data.id : null,
          iccid: null,
          // For organization, unused now
          organization_id: this.state.newOrganization ? newOrg : this.state.organizationId,
          // The product plan id
          plan: CLOUD_CLASSROOM, //plan.id,
          // The product id
          product: CLOUD_CLASSROOM, //this.props.cart.product,
          // Number of seats
          quantity: this.state.quantity,
          terms_accepted: true,
        };

        if (newCard && params.coupon === null) {
          // the existing credit card id
          params['default_source'] = null;
          // The stripe token id, only for new credit cards
          params['stripe_token'] = submitData.token.id;
        } else if (params.coupon === null) {
          // the existing credit card id
          params['default_source'] = submitData;
          // The stripe token id, only for new credit cards
          params['stripe_token'] = null;
        }

        //If buying the same product with different plan call upgrade subscription ( sub_default is the default one that need to be excluded )

        if (
          this.props.subscription.byProduct[this.props.cart.product] &&
          this.props.subscription.byProduct[this.props.cart.product] !== 'sub_default'
        ) {
          if (isEduUpgrading) {
            this.setState({ purchaseInProgress: true });
            this.props.upgradeSubscription(
              planSubscription.id, //this.props.subscription.byProduct[CLOUD_CLASSROOM]
              params.plan,
              params.quantity,
              prorationDate
            );
            if (
              !(
                this.props.subscription.create.error ||
                this.props.subscription.upgrade.error ||
                this.props.subscription.create.inProgress ||
                this.props.subscription.upgrade.inProgress ||
                this.props.charge.create.error ||
                this.props.charge.create.inProgress
              )
            ) {
              setTimeout(() => {
                window.location.replace(`${CLOUD_HOME}members?space_id=${params.organization_id}`);
              }, 15000);
            } else if (!this.props.subscription.upgrade.error) {
              setTimeout(() => {
                window.location.replace(`${CLOUD_HOME}members?space_id=${params.organization_id}`);
              }, 15000);
            }
            this.setState({ purchaseInProgress: false });
          } else {
            //New organization - Create organization and use it for create Subscription
            this.setState({ purchaseInProgress: true });
            // Se sono qui ed è già creata non la creo e compito organization (sopra), altrimenti la creo con organization (sopra)
            const organization = {
              name:
                this.props.billing.billingInfo.company !== ''
                  ? this.props.billing.billingInfo.company
                  : DEFAULT_COMPANY,
              type: OTHER,
              country: this.props.billing.billingInfo.country,
              space: SPACE_EDUCATION_PLAN,
            };

            let existOrganization = false;

            this.props.organizations.organizations.forEach((org) => {
              if (org.id === this.state.organizationId) {
                existOrganization = true;
                organization['name'] = org.name;
                organization['type'] = org.type;
                organization['country'] = org.country;
                organization['space'] = org.space;
              }
            });
            if (!existOrganization) {
              this.props.createOrganization(organization);
            }

            setTimeout(() => {
              this.props.readOrganizationList();
            }, 2000);

            setTimeout(() => {
              this.props.organizations.organizations.forEach((org) => {
                if (org.name === organization.name) {
                  params['organization_id'] = org.id;
                }
              });

              this.props.createSubscription(params);
            }, 4000);

            if (
              !(
                this.props.subscription.create.error ||
                this.props.subscription.upgrade.error ||
                this.props.subscription.create.inProgress ||
                this.props.subscription.upgrade.inProgress ||
                this.props.charge.create.error ||
                this.props.charge.create.inProgress
              )
            ) {
              setTimeout(() => {
                window.location.replace(`${CLOUD_HOME}members?space_id=${params.organization_id}`);
              }, 15000);
            } else if (!this.props.subscription.create.error || !this.props.charge.create.error) {
              setTimeout(() => {
                window.location.replace(`${CLOUD_HOME}members?space_id=${params.organization_id}`);
              }, 15000);
            }
            this.setState({ purchaseInProgress: true });
          }
        } else {
          //Create organization and use it for create Subscription
          this.setState({ purchaseInProgress: true });
          const organization = {
            name:
              this.props.billing.billingInfo.company !== '' ? this.props.billing.billingInfo.company : DEFAULT_COMPANY,
            type: OTHER,
            country: this.props.billing.billingInfo.country,
            space: SPACE_EDUCATION_PLAN,
          };

          let existOrganization = false;

          this.props.organizations.organizations.forEach((org) => {
            if (org.id === this.state.organizationId) {
              existOrganization = true;
              organization['name'] = org.name;
              organization['type'] = org.type;
              organization['country'] = org.country;
              organization['space'] = org.space;
            }
          });
          if (!existOrganization) {
            this.props.createOrganization(organization);
          }

          setTimeout(() => {
            this.props.readOrganizationList();
          }, 2000);

          setTimeout(() => {
            this.props.organizations.organizations.forEach((org) => {
              if (org.name === organization.name) {
                params['organization_id'] = org.id;
              }
            });
            this.props.createSubscription(params);
          }, 4000);

          if (
            !(
              this.props.subscription.create.error ||
              this.props.subscription.upgrade.error ||
              this.props.subscription.create.inProgress ||
              this.props.subscription.upgrade.inProgress ||
              this.props.charge.create.error ||
              this.props.charge.create.inProgress
            )
          ) {
            setTimeout(() => {
              window.location.replace(`${CLOUD_HOME}members?space_id=${params.organization_id}`);
            }, 15000);
          } else if (!this.props.subscription.create.error || !this.props.charge.create.error) {
            setTimeout(() => {
              window.location.replace(`${CLOUD_HOME}members?space_id=${params.organization_id}`);
            }, 15000);
          }
          this.setState({ purchaseInProgress: false });
        } //End
        if (!isEduUpgrading) {
          this.planPurchase({
            plan_detail: this.state.quantity,
            value: this.state.finalPrice,
            tax: this.props.taxes?.data.taxes || 0,
            currency: 'USD',
            plan_type: 'school',
            plan_level: '',
            billing_type: '',
            is_update: this.state.isEduUpgrading,
          });
        }
      }
    }, 2000);
  }

  handleQueryStringPlan(id) {
    this.setState({ organizationId: id });
  }

  handleCreateOrganization(newOrganization) {
    if (newOrganization.name !== '' && newOrganization.type !== '' && newOrganization.country !== '') {
      const organization = {
        name: newOrganization.name,
        type: newOrganization.type !== OTHER ? newOrganization.type : newOrganization.other,
        country: newOrganization.country,
        space: SPACE_EDUCATION_PLAN,
      };
      this.props.createOrganization(organization);
      setTimeout(() => {
        this.props.readOrganizationList();
      }, 1000);
    }
  }

  handleOrganizationSelected(newOrganization) {
    this.setState({
      organizationId: newOrganization,
    });
  }

  handleSetOrganization(newOrganization) {
    this.setState({
      organizationId: newOrganization.selected,
      billingOrganization: {
        name: newOrganization.selected,
        country: null,
      },
    });
    this.props.readOrganizationList();
    this.props.history.push(`${this.props.match.path}/plan`);
  }

  handlePaymentBack() {
    this.props.createSubscriptionClearError();
    this.props.upgradeSubscriptionClearError();
    this.props.history.push(`${this.props.match.path}/billing`);
  }

  handleSelectedCountry(code) {
    this.props.updateBillingCountry(code);
  }

  handleSelectedState(code) {
    this.props.updateBillingState(code);
  }

  handleQuantityOfSeats(values) {
    this.setState({ quantity: values, planUpdated: true });
    //avoid the loop
  }

  handleBack() {
    this.setState({ planUpdated: false });
    window.history.back();
  }

  render() {
    const spinner = (
      <div className="purchase">
        <AbsoluteSpinner height={'calc(100vh - 50px)'} clouds />
      </div>
    );

    if (!this.props.cart.plan) {
      return spinner;
    }

    if (this.state.infiniteSpinner || this.state.infiniteCertificationSpinner) {
      return spinner;
    }

    const plan = this.props.plan.plans[this.props.cart.plan];
    const quantity = getQuantityNumber(this.props.cart.quantity);
    const price = isNaN(parseFloat(this.props.cart.price)) ? plan.price : parseFloat(this.props.cart.price);
    if (
      this.props.plan.trial.inProgress ||
      this.props.profile.lastUpdate === null ||
      this.props.country.readList.lastUpdate === null ||
      !this.props.userCountry.readList ||
      this.props.userCountry.readList.lastUpdate === null ||
      !this.props.subscription.readList ||
      this.props.subscription.readList.lastUpdate === null
    ) {
      return spinner;
    }

    if (plan.type === 'subscription' && this.props.plan.trial.lastUpdate === null) {
      return spinner;
    }

    let freeTrial = null;

    if (this.props.plan.trial.error) {
      freeTrial = {
        has_trial: false,
        trial_available: false,
      };
    } else {
      freeTrial = this.props.plan.trial.data;
    }

    const purchaseInProgress =
      this.props.subscription.create.inProgress ||
      this.props.subscription.upgrade.inProgress ||
      this.props.charge.create.inProgress;

    if (purchaseInProgress || this.state.loading) {
      return spinner;
    }

    const educationPath = this.props.match.path === EDUCATION_PURCHASE;
    const cloudPath = this.props.match.path === CLOUD_PURCHASE;
    const certificationPath = this.props.match.path === CERTIFICATION_PURCHASE;
    const enterprisePath = this.props.match.path === ENTERPRISE_PURCHASE;

    let anOrganizationhasPlan = false;
    let isEduUpgrading = false;
    this.props?.organizations?.organizations.forEach((organization) => {
      if (organization.plan_id !== '' && organization.id === this.state.organizationId) anOrganizationhasPlan = true;
    });
    if (!this.state.newOrganization && anOrganizationhasPlan) isEduUpgrading = true;

    let planSubscription = this.props.subscription.byId[this.props.subscription.byPlan[CLOUD_CLASSROOM]];
    for (const key in this.props.subscription.byId) {
      if (this.props.subscription.byId.hasOwnProperty(key)) {
        if (
          this.props.subscription.byId[key].plan === CLOUD_CLASSROOM &&
          this.props.subscription.byId[key].organization_id === this.state.organizationId
        ) {
          planSubscription = this.props.subscription.byId[key];
        }
      }
    }
    const isCloudUpgrading = this.state.currentCloudSubscription.plan !== CREATE_FREE && cloudPath;
    return (
      <div className="purchase">
        <div className="product-section">
          <div className="form-section">
            <div className="form-name-container">
              {educationPath && (
                <EducationBreadcrumbs
                  history={this.props.history}
                  location={this.props.location}
                  path={this.props.match.path}
                  active={this.props.location.pathname}
                  isEduUpgrading={isEduUpgrading}
                  institutionPageVisited={this.state.institutionPageVisited}
                  selectPlanPageVisited={this.state.selectPlanPageVisited}
                  billingPageVisited={this.state.billingPageVisited}
                  paymentPageVisited={this.state.paymentPageVisited}
                />
              )}
              {cloudPath && (
                <CloudBreadcrumbs
                  history={this.props.history}
                  active={this.props.location.pathname}
                  isCloudUpgrading={isCloudUpgrading}
                  selectPlanPageVisited={this.state.selectPlanPageVisited}
                  billingPageVisited={this.state.billingPageVisited}
                  paymentPageVisited={this.state.paymentPageVisited}
                />
              )}
              {certificationPath && (
                <CertificationBreadcrumbs
                  history={this.props.history}
                  examTypePageVisited={this.state.examTypePageVisited}
                  selectPlanPageVisited={this.state.selectPlanPageVisited}
                  billingPageVisited={this.state.billingPageVisited}
                  paymentPageVisited={this.state.paymentPageVisited}
                  active={this.props.location.pathname}
                />
              )}
              {enterprisePath && (
                <EnterpriseBreadcrumbs
                  active={this.props.location.pathname}
                  history={this.props.history}
                  addonsPlanPageVisited={this.state.addonsPlanPageVisited}
                  selectPlanPageVisited={this.state.selectPlanPageVisited}
                  billingPageVisited={this.state.billingPageVisited}
                  paymentPageVisited={this.state.paymentPageVisited}
                />
              )}
              <Switch>
                <Route path={`${this.props.match.path}/institution`}>
                  <div className="title">{'Your institution'}</div>
                  <div className="sub-title">
                    To get started with your plan, add information about your institution.
                  </div>
                  <InstitutionConfiguration
                    profile={this.props.profile}
                    onSubmit={this.handleInstitutionSubmit}
                    subscriptions={this.props.subscription}
                    organizations={this.props.organizations}
                    onCreateOrganization={this.handleCreateOrganization}
                    country={this.props.country}
                    handleOrganizationSelected={this.handleOrganizationSelected}
                    actualOrganization={this.state.organizationId}
                    handleEduInstitutionCta={this.handleEduInstitutionCta}
                    history={this.props.history}
                    match={this.props.match}
                  />
                </Route>
                {educationPath && (
                  <Route path={`${this.props.match.path}/plan`}>
                    <div className="title">{isEduUpgrading ? 'Modify your plan' : 'Configure your plan'}</div>
                    <div className="sub-title">Your plan will be applied to all members of your classroom.</div>
                    <EduPlanConfiguration
                      plan={this.props.cart}
                      match={this.props.match}
                      subscription={planSubscription}
                      lastTotalUpgradePrice={this.props.subscription.lastTotalUpgradePrice}
                      organizations={this.props.organizations}
                      getUpgradePreview={(subId, planName, quantity) =>
                        this.getUpgradePreview(subId, planName, quantity)
                      }
                      seatsQuantity={this.state.quantity}
                      changeQuantityHandler={(value) => this.handleQuantityOfSeats(value)}
                      onSubmit={this.handlePlanSubmit}
                      isEduUpgrading={isEduUpgrading}
                      billingOrganization={this.state.billingOrganization}
                      planUpdated={this.state.planUpdated}
                      handleQueryStringPlan={(id) => this.handleQueryStringPlan(id)}
                      queryStringOrganization={this.state.queryStringOrganization}
                      handleEduConfigurePlanCta={this.handleEduConfigurePlanCta}
                    />
                  </Route>
                )}
                {cloudPath && (
                  <Route path={`${this.props.match.path}/plan`}>
                    <div className="title">{isCloudUpgrading ? 'Modify your plan' : 'Configure your plan'}</div>
                    <CloudPlanConfiguration
                      onSubmit={(frequency, type) => this.handleCloudPlanSubmit(frequency, type)}
                      cloudPlanFrequency={this.state.cloudPlanFrequency}
                      cloudPlanType={this.state.cloudPlanType}
                      currentCloudSubscription={
                        this.state.currentCloudSubscription === '' ? {} : this.state.currentCloudSubscription
                      }
                      handleUpdateCloudPlanFrequency={(frequency) => this.handleUpdateCloudPlanFrequency(frequency)}
                      handleUpdateCloudPlanName={(name) => this.handleUpdateCloudPlanName(name)}
                      handleChangeCloudDowngrading={(value) => this.handleChangeCloudDowngrading(value)}
                      isCloudUpgrading={isCloudUpgrading}
                      getUpgradePreview={(subId, planName, quantity) =>
                        this.getUpgradePreview(subId, planName, quantity)
                      }
                      handleCloudPlanDisabled={this.handleCloudPlanDisabled}
                    />
                  </Route>
                )}
                {certificationPath && (
                  <Route path={`${this.props.match.path}/exam-type`}>
                    <div className="title">{'Choose your exam type'}</div>
                    <div className="sub-title">
                      You can choose from two different certifications - Junior Certification and the Arduino
                      Certification Program.
                    </div>
                    <CertificationExamTypeConfiguration
                      onSubmit={(type) => this.handleCertificationExamTypeSubmit(type)}
                      certificationType={this.state.certificationType}
                      progress={this.props.billing.inProgress}
                      resetFlow={() => this.resetFlow()}
                    />
                  </Route>
                )}
                {certificationPath && (
                  <Route path={`${this.props.match.path}/plan`}>
                    <div className="title">{'Choose number of exam codes'}</div>
                    <div className="sub-title">
                      Each exam code allows 1 person to attempt{' '}
                      {this.state.certificationType === CERTIFICATION_PLAN
                        ? 'Arduino Certification'
                        : 'Arduino Junior Certification'}
                      .
                      <br />
                      You can purchase multiple codes and share them with your class or organisation.
                    </div>
                    <CertificationPlanConfiguration
                      changeQuantityHandler={(value) => this.handleCertificationNumber(value)}
                      onSubmit={() => this.handleCertificationBillingSubmit()}
                      progress={this.props.billing.inProgress}
                      examCodes={this.state.examCodes}
                      certificationType={this.state.certificationType}
                    />
                  </Route>
                )}
                {enterprisePath && (
                  <Route path={`${this.props.match.path}/plan`}>
                    <div className="title">Customize your plan</div>
                    <div className="sub-title">Add extended functionality and integrations to your cloud plan</div>
                    <EnterprisePlanConfiguration
                      onSubmit={(frequency, type) => this.handleEnterprisePlanSubmit(frequency, type)}
                      enterprisePlanFrequency={this.state.enterprisePlanFrequency}
                      enterprisePlanThings={this.state.enterprisePlanThings}
                      handleUpdateEnterprisePlanFrequency={(frequency) =>
                        this.handleUpdateEnterprisePlanFrequency(frequency)
                      }
                      handleUpdateEnterprisePlanThings={(things) => this.handleUpdateEnterprisePlanThings(things)}
                      handleEnterpriseConfigurePlanCta={this.handleEnterpriseConfigurePlanCta}
                      spaceId={this.state.enterpriseSpaceId}
                    />
                  </Route>
                )}
                {enterprisePath && (
                  <Route path={`${this.props.match.path}/addons`}>
                    <div className="title">Select add-ons</div>
                    <div className="sub-title">
                      Add-ons add extended functionality and integrations to your cloud plan
                    </div>
                    <EnterpriseAddonsConfiguration
                      onSubmit={() => this.handleEnterpriseAddonsSubmit()}
                      history={this.props.history}
                      match={this.props.match}
                      x8DevicesNumber={this.state.x8DevicesNumber}
                      enterprisePlanAddons={this.state.enterprisePlanAddons}
                      enterprisePlanThings={this.state.enterprisePlanThings}
                      enterprisePlanSelected={this.state.enterprisePlanSelected}
                      enterprisePath={enterprisePath}
                      handleUpdateEnterpriseAddons={(addons) => this.handleUpdateEnterpriseAddons(addons)}
                      handleUpdateEnterpriseX8Devices={(devicesNumber) =>
                        this.handleUpdateEnterpriseX8Devices(devicesNumber)
                      }
                      enterprisePlanFrequency={this.state.enterprisePlanFrequency}
                    />
                  </Route>
                )}
                <Route path={`${this.props.match.path}/billing`}>
                  <h3>{'Your billing information'}</h3>
                  <BillingInfoForm
                    cart={this.props.cart}
                    profile={this.props.profile}
                    certificates={this.props.certificates}
                    readStates={this.props.readStates}
                    onSubmit={this.handleEditBillingInfo}
                    match={this.props.match}
                    location={this.props.location}
                    onSelectedCountry={this.handleSelectedCountry}
                    onSelectedState={this.handleSelectedState}
                    edit={this.props.profile.data.billing ? false : true}
                    wellContent={
                      this.props.profile.data.billing?.name !== '' &&
                      this.props.profile.data.billing?.street1 !== '' &&
                      this.props.profile.data.billing?.postcode !== '' &&
                      this.props.profile.data.billing?.type !== '' &&
                      this.props.profile.data.billing?.country !== ''
                    }
                    country={this.props.country}
                    submitButtonText={'Add payment information'}
                    viewButtonText={'Add payment information'}
                    progress={this.props.billing.inProgress}
                    availableCountries={this.props.userCountry.availableCountriesList}
                    educationPath={educationPath}
                    enterprisePath={enterprisePath}
                    enterprisePlanSelected={this.state.enterprisePlanSelected}
                    billingOrganization={this.state.billingOrganization}
                    organizations={this.props.organizations}
                    history={this.props.history}
                    cloudPlanFrequency={this.state.cloudPlanFrequency}
                    cloudPlanType={this.state.cloudPlanType}
                    cloudPath={cloudPath}
                    handleBillingDisabled={this.handleBillingDisabled}
                    billingTrackingSended={this.state.billingTrackingSended}
                  />
                </Route>
                <Route path={`${this.props.match.path}/payment`}>
                  <h3>{'Payment information'}</h3>
                  <Payment
                    history={this.props.history}
                    match={this.props.match}
                    stripePublicKey={STRIPE_PUBLIC_KEY}
                    onNewCardSubmit={(stripeData) =>
                      educationPath
                        ? this.handleClassroomPaymentSubmit(stripeData, true)
                        : cloudPath
                        ? this.handlePaymentSubmit(stripeData, true)
                        : enterprisePath
                        ? this.handleEnterpriseSubmit(stripeData, true)
                        : certificationPath
                        ? this.handleCertificationSubmit(stripeData, false)
                        : this.handleJuniorCertificationSubmit(stripeData, false)
                    }
                    onExistingCardSubmit={(cardId) =>
                      educationPath
                        ? this.handleClassroomPaymentSubmit(cardId, false)
                        : cloudPath
                        ? this.handlePaymentSubmit(cardId, false)
                        : enterprisePath
                        ? this.handleEnterpriseSubmit(cardId, false)
                        : certificationPath
                        ? this.handleCertificationSubmit(cardId, false)
                        : this.handleJuniorCertificationSubmit(cardId, false)
                    }
                    card={this.props.card}
                    cart={this.props.cart}
                    onCardDelete={this.handleCardDelete}
                    retrievingCards={this.props.card.readList.inProgress}
                    actionInProgress={purchaseInProgress || this.props.taxes.inProgress}
                    errorOnBuying={
                      this.props.subscription.create.error ||
                      this.props.subscription.upgrade.error ||
                      this.props.charge.create.error
                    }
                    errorOnCreateCard={this.props.card.create.error}
                    createCard={(stripeToken) => this.handleCreateCard(stripeToken)}
                    isEduUpgrading={isEduUpgrading}
                    isCloudUpgrading={isCloudUpgrading}
                    educationPath={educationPath}
                    certificationPath={certificationPath}
                    verifyCoupon={this.props.verifyCoupon}
                    enterprisePath={enterprisePath}
                    enterprisePlanSelected={this.state.enterprisePlanSelected}
                    enterprisePlanFrequency={this.state.enterprisePlanFrequency}
                    cloudPath={cloudPath}
                    cloudPlanType={this.state.cloudPlanType}
                    cloudPlanFrequency={this.state.cloudPlanFrequency}
                    coupon={this.props.coupon}
                    plan={this.props.plan}
                    handlePaymentForm={this.handlePaymentForm}
                    paymentTrackingSended={this.state.paymentTrackingSended}
                    handlePaymentTrackingSender={this.handlePaymentTrackingSender}
                    handlePaymentPageVisited={this.handlePaymentPageVisited}
                    certificationType={this.state.certificationType}
                  />
                </Route>
                {/* Payment + Billing for UPGRADE */}
                <Route path={`${this.props.match.path}/payment-info`}>
                  <h3>{'Payment information'}</h3>
                  <PaymentInformation
                    profile={this.props.profile}
                    certificates={this.props.certificates}
                    readStates={this.props.readStates}
                    onSubmit={this.handleEditBillingInfo}
                    onSelectedCountry={this.handleSelectedCountry}
                    onSelectedState={this.handleSelectedState}
                    edit={this.props.profile.data.billing ? false : true}
                    country={this.props.country}
                    submitButtonText={'Add payment information'}
                    viewButtonText={'Add payment information'}
                    progress={this.props.billing.inProgress}
                    availableCountries={this.props.userCountry.availableCountriesList}
                    createCard={(stripeToken) => this.handleCreateCard(stripeToken)}
                    cloudPlanFrequency={this.state.cloudPlanFrequency}
                    cloudPlanType={this.state.cloudPlanType}
                    cloudPath={cloudPath}
                    match={this.props.match}
                    history={this.props.history}
                    educationPath={educationPath}
                    /* End props for billingInfo */
                    stripePublicKey={STRIPE_PUBLIC_KEY}
                    onNewCardSubmit={(stripeData) =>
                      educationPath
                        ? this.handleClassroomPaymentSubmit(stripeData, true)
                        : this.handlePaymentSubmit(stripeData, true)
                    }
                    onExistingCardSubmit={(cardId) =>
                      educationPath
                        ? this.handleClassroomPaymentSubmit(cardId, false)
                        : this.handlePaymentSubmit(cardId, false)
                    }
                    card={this.props.card}
                    onCardDelete={this.handleCardDelete}
                    retrievingCards={this.props.card.readList.inProgress}
                    actionInProgress={purchaseInProgress || this.props.taxes.inProgress}
                    errorOnBuying={
                      this.props.subscription.create.error ||
                      this.props.subscription.upgrade.error ||
                      this.props.charge.create.error
                    }
                    errorOnCreateCard={this.props.card.create.error}
                    updateBillingInfoFunc={this.props.updateBillingInfo}
                    handlePaymentInfoDisabled={this.handlePaymentInfoDisabled}
                    handleBillingDisabled={this.handleBillingDisabled}
                    certificationType={this.state.certificationType}
                  />
                </Route>
                <Route path={`${this.props.match.path}/alternative-payment`}>
                  <h3>{'Payment information'}</h3>
                  <AlternativePayment
                    match={this.props.match}
                    history={this.props.history}
                    handlePaymentTerms={this.handlePaymentTerms}
                    billingPageVisited={this.state.billingPageVisited}
                  />
                </Route>
              </Switch>
            </div>
          </div>
          <div className="details-section">
            {/* <WrappedSticky top={100}> */}
            <div className="details-section-container">
              {educationPath ? (
                //Education Cloud Classroom
                <ProductDetails
                  cart={this.props.cart}
                  card={this.props.card}
                  plan={plan}
                  quantity={this.state.quantity}
                  price={price ? price : 0}
                  freeTrial={freeTrial}
                  progress={this.props.billing.inProgress}
                  history={this.props.history}
                  match={this.props.match}
                  onInstitutionSubmit={this.handleInstitutionSubmit}
                  onBillingSubmit={this.handleBillingSubmit}
                  onPlanSubmit={this.handlePlanSubmit}
                  subscription={this.props.subscription}
                  isEduUpgrading={isEduUpgrading}
                  changeQuantityOfCurrentInstitution={this.changeQuantityOfCurrentInstitution}
                  organization={this.props.organizations}
                  actualSubscription={planSubscription}
                  certificates={this.props.certificates}
                  eduInstitutionCtaDisabled={this.state.eduInstitutionCtaDisabled}
                  eduConfigurePlanDisabled={this.state.eduConfigurePlanDisabled}
                  enterpriseConfigurePlanDisabled={this.state.enterpriseConfigurePlanDisabled}
                  handleEditBillingInfo={this.handleEditBillingInfo}
                  billingDisabled={this.state.billingDisabled}
                  paymentProgress={this.state.paymentProgress}
                  terms={this.state.terms}
                  fullValidCoupon={this.state.fullValidCoupon}
                  cloudPlanDisabled={this.state.cloudPlanDisabled}
                  paymentInfoDisabled={this.state.paymentInfoDisabled}
                  newCard={this.state.newCard}
                  purchaseInProgress={this.state.purchaseInProgress}
                  x8DevicesNumber={this.state.x8DevicesNumber}
                  deleteCouponBanner={this.state.deleteCouponBanner}
                  handleAlternativePaymentSubmit={
                    educationPath
                      ? this.handleEducationAlternativePaymentSubmit
                      : this.handleEnterpriseAlternativePaymentSubmit
                  }
                  setZapierPrice={(value) => this.setState({ price: value })}
                  setFinalPrice={this.setFinalPrice}
                />
              ) : (
                <ProductDetails
                  cart={this.props.cart}
                  card={this.props.card}
                  plan={plan}
                  quantity={quantity}
                  examCodes={this.state.examCodes}
                  juniorExamCodes={this.state.juniorExamCodes}
                  price={price}
                  freeTrial={freeTrial}
                  progress={this.props.billing.inProgress}
                  history={this.props.history}
                  match={this.props.match}
                  onInstitutionSubmit={this.handleInstitutionSubmit}
                  onBillingSubmit={this.handleBillingSubmit}
                  onPlanSubmit={this.handlePlanSubmit}
                  subscription={this.props.subscription}
                  isEduUpgrading={isEduUpgrading}
                  certificates={this.props.certificates}
                  isCloudUpgrading={isCloudUpgrading}
                  cloudPlanFrequency={this.state.cloudPlanFrequency}
                  cloudPlanType={this.state.cloudPlanType}
                  isCloudDowngrading={this.state.isCloudDowngrading}
                  enterprisePlanAddons={this.state.enterprisePlanAddons}
                  enterprisePlanThings={this.state.enterprisePlanThings}
                  enterprisePlanFrequency={this.state.enterprisePlanFrequency}
                  eduInstitutionCtaDisabled={this.state.eduInstitutionCtaDisabled}
                  eduConfigurePlanDisabled={this.state.eduConfigurePlanDisabled}
                  enterpriseConfigurePlanDisabled={this.state.enterpriseConfigurePlanDisabled}
                  handleEditBillingInfo={this.handleEditBillingInfo}
                  billingDisabled={this.state.billingDisabled}
                  paymentProgress={this.state.paymentProgress}
                  terms={this.state.terms}
                  fullValidCoupon={this.state.fullValidCoupon}
                  cloudPlanDisabled={this.state.cloudPlanDisabled}
                  paymentInfoDisabled={this.state.paymentInfoDisabled}
                  newCard={this.state.newCard}
                  purchaseInProgress={this.state.purchaseInProgress}
                  x8DevicesNumber={this.state.x8DevicesNumber}
                  deleteCouponBanner={this.state.deleteCouponBanner}
                  handleAlternativePaymentSubmit={
                    educationPath
                      ? this.handleEducationAlternativePaymentSubmit
                      : this.handleEnterpriseAlternativePaymentSubmit
                  }
                  setZapierPrice={(value) => this.setState({ price: value })}
                  certificationType={this.state.certificationType}
                  setFinalPrice={this.setFinalPrice}
                />
              )}
            </div>
            {/* </WrappedSticky> */}
          </div>
        </div>
      </div>
    );
  }
}

Purchase.propTypes = {
  charge: PropTypes.object.isRequired,
  createSubscription: PropTypes.func,
  createCharge: PropTypes.func.isRequired,
  upgradeSubscription: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  updateBillingInfo: PropTypes.func,
  cart: PropTypes.object,
  product: PropTypes.object,
  plan: PropTypes.object,
  readCardList: PropTypes.func,
  deleteCard: PropTypes.func,
  createCard: PropTypes.func,
  card: PropTypes.object,
  subscription: PropTypes.object,
  createSubscriptionClearError: PropTypes.func,
  upgradeSubscriptionClearError: PropTypes.func,
  getPlanTrial: PropTypes.func,
  clearPlanTrial: PropTypes.func,
  getProfile: PropTypes.func,
  profile: PropTypes.object,
  readOrganizationList: PropTypes.func,
  organizations: PropTypes.object,
  certificates: PropTypes.array,
  readCountries: PropTypes.func,
  readStates: PropTypes.func,
  readSubscriptionList: PropTypes.func,
  readSubscription: PropTypes.func,
  createOrganization: PropTypes.func,
  createRequest: PropTypes.func,
  updateBillingCountry: PropTypes.func,
  updateBillingState: PropTypes.func,
  setCart: PropTypes.func,
  unsetCart: PropTypes.func,
  clearCoupon: PropTypes.func,
  coupon: PropTypes.object,
  country: PropTypes.shape({
    countriesList: PropTypes.array,
    statesList: PropTypes.array,
    readList: PropTypes.object,
  }),
  billing: PropTypes.object,
  readAvailableCountriesList: PropTypes.func,
  clearChargeError: PropTypes.func,
  userCountry: PropTypes.object,
  taxes: PropTypes.object,
  trackDowngrade: PropTypes.func,
  updateSubscriptionCard: PropTypes.func,
  readUpgradePreview: PropTypes.func,
  verifyCoupon: PropTypes.func,
};

/**
 * HOC to wrap the Purchase component and prevent it do make double calls to APIs
 * on mount
 */
function withPurchaseRedirecRouter(Wrapped) {
  const PurchaseRedirecRouter = function PurchaseRedirecRouter(props) {
    localStorage.removeItem('path');
    const location = useLocation();
    const educationPath = props.match.path === EDUCATION_PURCHASE;
    const educationPlanPath = location.pathname === EDUCATION_PURCHASE_PLAN;

    useEffect(() => {
      window.arduinoHeader.renderMenuButtons([]);
      window.arduinoHeader.changeLogo({
        src: '<svg width="52" height="26" viewBox="0 0 52 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M51.25 13C51.25 6.10219 45.5773 0.5 38.6242 0.5C37.984 0.5 37.3261 0.536496 36.6859 0.645985C31.2799 1.43066 27.6522 5.42701 25.625 8.4927C23.5978 5.42701 19.9701 1.43066 14.5641 0.645985C13.9239 0.554745 13.266 0.5 12.6258 0.5C5.65493 0.5 0 6.10219 0 13C0 19.8978 5.67271 25.5 12.6258 25.5C13.266 25.5 13.9239 25.4635 14.5819 25.354C19.9879 24.5511 23.6155 20.5547 25.6428 17.4891C27.67 20.5547 31.2977 24.5511 36.7037 25.354C37.3439 25.4453 38.0018 25.5 38.6598 25.5C45.5773 25.5 51.25 19.8978 51.25 13ZM13.9416 20.9744C13.4971 21.0474 13.0525 21.0657 12.6079 21.0657C8.01996 21.0657 4.30336 17.4343 4.30336 13C4.30336 8.54744 8.03774 4.9343 12.6257 4.9343C13.0703 4.9343 13.5148 4.9708 13.9594 5.02554C19.0631 5.77372 22.1751 10.9379 23.242 13C22.1573 15.0803 19.0275 20.2263 13.9416 20.9744ZM37.2905 5.02554C32.1868 5.77372 29.057 10.9379 28.0079 13C29.057 15.062 32.1868 20.2263 37.2905 20.9744C37.735 21.0292 38.1796 21.0657 38.6242 21.0657C43.1944 21.0657 46.9287 17.4525 46.9287 13C46.9287 8.56569 43.2121 4.9343 38.6242 4.9343C38.1796 4.9343 37.735 4.9708 37.2905 5.02554ZM9.06449 11.661H16.7004V14.0642H9.06449V11.661ZM42.1479 14.0817H39.5728V16.6077H37.1049V14.0817H34.5298V11.661H37.1049V9.13499H39.5728V11.661H42.1479V14.0817Z" fill="#00979D"/></svg>',
        inline: true,
        cssClass: 'digital-store',
        url: CREATE_BASE_URL,
      });
    });

    if (CHECKOUT_MAINTENANCE) {
      return <CheckoutMaintenance />;
    }

    const appsMenu = document.getElementsByClassName('app-applications popup-dropdown');
    if (appsMenu[0] && educationPath) appsMenu[0].style.display = 'none';

    if (location.pathname === EDUCATION_PURCHASE_PLAN && location.search.substring(17) !== '') {
      localStorage.setItem('organization', location.search.substring(17));
    }

    const plan = new URLSearchParams(location.search).get('plan');
    const frequency = new URLSearchParams(location.search).get('frequency');
    const spaceId = new URLSearchParams(location.search).get('space_id');
    localStorage.setItem('cloudPlanName', !plan ? 'maker' : plan.toLowerCase());
    localStorage.setItem('cloudPlanFrequency', frequency);
    localStorage.setItem('enterpriseSpaceId', spaceId);
    if (location.pathname === '/enterprise/purchase/plan' && frequency !== null) {
      localStorage.setItem('enterprisePlanFrequency', frequency);
      localStorage.removeItem('cloudPlanName');
    } else if (frequency === null) localStorage.setItem('enterprisePlanFrequency', 'monthly');

    let redirectPlanLink = educationPath
      ? educationPlanPath || localStorage.getItem('organization')
        ? `${props.match.path}/plan`
        : `${props.match.path}/institution`
      : props.cart.product === 'cert-fundamentals'
      ? `${props.match.path}/billing`
      : props.cart.product === 'certifications_starterkit_bulk'
      ? `${props.match.path}/exam-type`
      : `${props.match.path}/plan`;

    redirectPlanLink =
      props.match.url === '/certification/purchase' ? `${props.match.path}/exam-type` : redirectPlanLink;
    return (
      <Switch>
        <Route exact path={props.match.path} render={() => <Redirect to={redirectPlanLink} />} />
        <Route>
          <Wrapped {...props} />
        </Route>
      </Switch>
    );
  };

  PurchaseRedirecRouter.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    subscription: PropTypes.object,
    cart: PropTypes.object,
  };

  return PurchaseRedirecRouter;
}

export default withPurchaseRedirecRouter(Purchase);
