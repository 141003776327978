/* eslint-disable */

/**
 * This file exposes the variables passed from Webpack and read from the current env file (/env/*.sh)
 */

export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_DESCRIPTION = process.env.REACT_APP_APP_DESCRIPTION;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const ACTIVATION_API_URL = process.env.REACT_APP_ACTIVATIONS_API_URL;
export const COUNTRIES_API_URL = process.env.REACT_APP_COUNTRIES_API_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const CREATE_BASE_URL = process.env.REACT_APP_CREATE_BASE_URL;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const CREATE_IOT_CLOUD_URL = process.env.REACT_APP_CREATE_IOT_CLOUD_URL;
export const CREATE_EDITOR_URL = process.env.REACT_APP_CREATE_EDITOR_URL;
export const CREATE_DEVICES_URL = process.env.REACT_APP_CREATE_DEVICES_URL;
export const PROJECT_HUB_URL = process.env.REACT_APP_PROJECT_HUB_URL;
export const STORE_URL = process.env.REACT_APP_STORE_URL;
export const MYPLANS_STORE_URL = process.env.REACT_APP_STORE_MYPLANS;
export const CLOUD_URL = process.env.REACT_APP_CLOUD_URL;
export const STORE_TERMS_URL = process.env.REACT_APP_STORE_TERMS_URL;
export const CERTIFICATION_ACTIVATION_URL = process.env.REACT_APP_CERTIFICATION_ACTIVATION_URL;
export const JUNIOR_CERTIFICATION_ACTIVATION_URL = process.env.REACT_APP_JUNIOR_CERTIFICATION_ACTIVATION_URL;
export const CERTIFICATION_DEMO_URL = process.env.REACT_APP_CERTIFICATION_DEMO_URL;
export const CERTIFICATION_FORM_URL = process.env.REACT_APP_CERTIFICATION_FORM_URL;
export const CERTIFICATION_GUIDE_PDF_URL = process.env.REACT_APP_CERTIFICATION_GUIDE_PDF_URL;
export const DISABLE_FEATURE_CREATE_PLANS = process.env.REACT_APP_DISABLE_FEATURE_CREATE_PLANS;
export const DRONE_COMMIT = process.env.REACT_APP_DRONE_COMMIT;
export const DRONE_COMMIT_BRANCH = process.env.REACT_APP_DRONE_COMMIT_BRANCH;
export const DRONE_BUILD_STARTED = process.env.REACT_APP_DRONE_BUILD_STARTED;
export const DRONE_BUILD_NUMBER = process.env.REACT_APP_DRONE_BUILD_NUMBER;
export const INTERCOM_WORKSPACE_ID = process.env.REACT_APP_INTERCOM_WORKSPACE_ID;
export const HF_URL = process.env.REACT_APP_HF_URL;
export const HF_ENV = process.env.REACT_APP_HF_ENV;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const DATADOG_TOKEN = process.env.REACT_APP_DATADOG_TOKEN;
export const TYPEFORM_DOWNGRADE_SURVEY_URL = process.env.REACT_APP_TYPEFORM_DOWNGRADE_SURVEY_URL;
export const API_MAINTENANCE_MODE = process.env.REACT_APP_API_MAINTENANCE_MODE === 'true';
export const NODE_ENV_DEV = NODE_ENV === 'development';
export const CHECKOUT_MAINTENANCE = process.env.REACT_APP_CHECKOUT_MAINTENANCE === 'true';

export const CLOUD_HOME = process.env.REACT_APP_CLOUD_HOME;
export const REACT_APP_CLOUD_LANDING_WEBSITE = process.env.REACT_APP_CLOUD_LANDING_WEBSITE;
export const REACT_APP_SEGMENT_SCRIPT = process.env.REACT_APP_SEGMENT_SCRIPT;
export const REACT_APP_SEGMENT_TOKEN = process.env.REACT_APP_SEGMENT_TOKEN;

export const ARDUINO_STORE = process.env.REACT_APP_ARDUINO_STORE;
export const CONTACT_US_URL = process.env.REACT_APP_CONTACT_US_URL;


export const AWS_CREATE_PATH = process.env.REACT_APP_AWS_CREATE_PATH;
