import { combineReducers } from 'redux';

// Add your reducers below, and don't forget to add them in the combineReducers objects too!
import authenticationReducers from './authentication';
import billingReducers from './billing';
import cardReducers from './card';
import cartReducers from './cart';
import chargeReducers from './charge';
import countryReducers from './country';
import invoiceReducers from './invoice';
import navigationReducers from './navigation';
import planReducers from './plan';
import productReducers from './product';
import subscriptionReducers from './subscription';
import activationReducers from './activations';
import checkUserCountryReducers from './checkUserCountry';
import couponReducers from './coupon';
import taxesReducers from './taxes';
import userRecapReducer from './userRecap';
import awsReducers from './aws';

export default combineReducers({
  authentication: authenticationReducers,
  billing: billingReducers,
  card: cardReducers,
  cart: cartReducers,
  charge: chargeReducers,
  country: countryReducers,
  invoice: invoiceReducers,
  navigation: navigationReducers,
  plan: planReducers,
  product: productReducers,
  subscription: subscriptionReducers,
  userCountry: checkUserCountryReducers,
  coupon: couponReducers,
  taxes: taxesReducers,
  userRecap: userRecapReducer,
  activation: activationReducers,
  aws: awsReducers,
});
