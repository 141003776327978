import { Config } from './ConfigDef';

const config: Config = {
  id: 'create',
  name: 'Cloud',
  choosePlanContainerId: 'plans',
  plans: {
    create_free: {
      id: 'create_free',
      billingPeriod: 'month',
      cancellable: false,
      fullName: 'Cloud Free Plan',
      planDescription: 'create_free',
      planFeatures: 'create_free',
      description: 'Everything you need to learn Arduino, build your first IoT project and control it from your phone.',
      price: 0,
      priceDescription: '',
      priceTrialDescription: '',
      profile: 'free',
      type: 'subscription',
      unique: true,
      upgradeable: true,
    },
    create_entry_monthly: {
      id: 'create_entry_monthly',
      billingPeriod: 'month',
      cancellable: true,
      fullName: 'Cloud Entry Plan',
      planDescription: 'create_entry',
      planFeatures: 'create_entry',
      description: 'Get unlimited storage, scale up your IoT projects and get access to advanced features.',
      price: 2.99,
      priceDescription: 'Monthly Subscription',
      priceTrialDescription: '',
      profile: 'free',
      type: 'subscription',
      unique: true,
      upgradeable: true,
      disabled: true,
    },
    create_entry_yearly: {
      id: 'create_entry_yearly',
      billingPeriod: 'year',
      cancellable: true,
      downgradeable: true,
      fullName: 'Maker Plan',
      planDescription: 'create_entry',
      planFeatures: 'create_entry',
      description: 'Get unlimited storage, scale up your IoT projects and get access to advanced features.',
      price: 23.88,
      priceDescription: 'Yearly Subscription',
      priceTrialDescription: '',
      profile: 'entry',
      type: 'subscription',
      unique: true,
      upgradeable: true,
    },
    create_maker_trial: {
      id: 'create_maker_trial',
      billingPeriod: 'month',
      cancellable: false,
      fullName: 'Maker Trial',
      planDescription: 'create_maker_trial',
      planFeatures: 'create_maker',
      description:
        'Connect the dots from inspiration to implementation. Set up your advanced environment and increase the productivity of your tools. All in the Base plan, plus:',
      price: 0,
      priceDescription: '',
      priceTrialDescription: '',
      profile: 'free',
      type: 'subscription',
      unique: true,
      upgradeable: true,
    },
    create_maker_monthly: {
      id: 'create_maker_monthly',
      billingPeriod: 'month',
      cancellable: true,
      downgradeable: true,
      fullName: 'Maker Plan',
      planDescription: 'create_maker',
      planFeatures: 'create_maker',
      description:
        'For makers that are getting serious and need a reliable and sophisticated IoT platform to run their projects.',
      price: 6.99,
      priceDescription: 'Monthly Subscription',
      priceTrialDescription: '',
      profile: 'maker',
      type: 'subscription',
      unique: true,
      upgradeable: true,
      // promotion: 'a coupon code to get a MKR WAN 1300 for free.',
    },
    create_maker_yearly: {
      id: 'create_maker_yearly',
      billingPeriod: 'year',
      cancellable: true,
      downgradeable: true,
      fullName: 'Maker Plan',
      planDescription: 'create_maker',
      planFeatures: 'create_maker',
      description:
        'For makers that are getting serious and need a reliable and sophisticated IoT platform to run their projects.',
      price: 71.88,
      priceDescription: 'Yearly Subscription',
      priceTrialDescription: '',
      profile: 'maker',
      type: 'subscription',
      unique: true,
      upgradeable: true,
      promotion: 'a 30$ coupon code to use on digital-store.arduino.cc',
    },
    create_makerplus_monthly: {
      id: 'create_makerplus_monthly',
      billingPeriod: 'month',
      cancellable: true,
      downgradeable: true,
      fullName: 'Maker Plus Plan',
      planDescription: 'create_makerplus',
      planFeatures: 'create_makerplus',
      description: 'The option for makers with ambitions, that need to manage a small fleet of connected devices.',
      price: 23.99,
      priceDescription: 'Monthly Subscription',
      priceTrialDescription: '',
      profile: 'maker_plus',
      type: 'subscription',
      unique: true,
      upgradeable: true,
    },
    create_makerplus_yearly: {
      id: 'create_makerplus_yearly',
      billingPeriod: 'year',
      cancellable: true,
      downgradeable: true,
      fullName: 'Maker Plus Plan',
      planDescription: 'create_makerplus',
      planFeatures: 'create_makerplus',
      description: 'The option for makers with ambitions, that need to manage a small fleet of connected devices.',
      price: 239.88,
      priceDescription: 'Yearly Subscription',
      priceTrialDescription: '',
      profile: 'maker',
      type: 'subscription',
      unique: true,
      upgradeable: true,
      promotion: 'a 30$ coupon code to use on digital-store.arduino.cc',
    },
    cloud_classroom_monthly: {
      id: 'cloud_classroom',
      billingPeriod: 'month',
      cancellable: true,
      fullName: 'Cloud Classroom Plan',
      planDescription: 'cloud_classroom',
      planFeatures: 'cloud_classroom',
      description: 'Cloud Classroom Plan',
      price: 0,
      priceDescription: 'Cloud Classroom Plan',
      priceTrialDescription: '',
      profile: 'free',
      type: 'subscription',
      unique: true,
      upgradeable: true,
      disabled: true,
    },
    cloud_classroom_yearly: {
      id: 'cloud_classroom',
      billingPeriod: 'year',
      cancellable: true,
      fullName: 'Cloud Classroom Plan',
      planDescription: 'cloud_classroom',
      planFeatures: 'cloud_classroom',
      description: 'Cloud Classroom Plan',
      price: 0,
      priceDescription: 'Cloud Classroom Plan',
      priceTrialDescription: '',
      profile: 'free',
      type: 'subscription',
      unique: true,
      upgradeable: true,
      disabled: true,
    },
    certifications_starterkit_bulk: {
      id: 'certifications_starterkit_bulk',
      planDescription: 'certifications_starterkit_bulk',
      description: 'Arduino Certification',
      profile: 'free',
      planFeatures: '',
      upgradeable: false,
      downgradeable: false,
      cancellable: true,
      unique: false,
      price: 30.0,
      priceDescription: '',
      priceTrialDescription: '',
      billingPeriod: 'month',
      type: 'subscription', //multiPurchase
      fullName: 'Arduino Certification',
    },
    junior_certification: {
      id: 'junior_certification',
      planDescription: 'junior_certification',
      description: 'Arduino Junior Certification',
      profile: 'free',
      planFeatures: '',
      upgradeable: false,
      downgradeable: false,
      cancellable: false,
      unique: false,
      price: 30.0,
      priceDescription: '',
      priceTrialDescription: '',
      billingPeriod: 'month',
      type: 'subscription', //multiPurchase
      fullName: 'Arduino Junior Certification',
    },
    cloud_enterprise_monthly: {
      id: 'cloud_enterprise_monthly',
      planDescription: 'cloud_enterprise_monthly',
      description: 'Enterprise plan',
      profile: 'free',
      planFeatures: '',
      upgradeable: false,
      downgradeable: false,
      cancellable: true,
      unique: false,
      price: 0.0,
      priceDescription: '',
      priceTrialDescription: '',
      billingPeriod: 'month',
      type: 'subscription', //multiPurchase
      fullName: 'Enterprise plan',
    },
    cloud_enterprise_yearly: {
      id: 'cloud_enterprise_yearly',
      planDescription: 'cloud_enterprise_yearly',
      description: 'Enterprise plan',
      profile: 'free',
      planFeatures: '',
      upgradeable: false,
      downgradeable: false,
      cancellable: true,
      unique: false,
      price: 0.0,
      priceDescription: '',
      priceTrialDescription: '',
      billingPeriod: 'year',
      type: 'subscription', //multiPurchase
      fullName: 'Enterprise plan',
    },
  },
  planFeatures: {
    create_free: [
      {
        amount: '100',
        description: 'Total Sketches',
      },
      {
        amount: '200s/day',
        description: 'Compilation Time',
      },
      {
        amount: '1',
        description: 'Thing',
      },
      {
        amount: '5',
        description: 'Variables',
      },
      {
        amount: '1 day',
        description: 'Cloud Data Retention',
      },
      {
        amount: '1',
        description: 'Cloud-enabled Linux Devices',
      },
      {
        amount: '5',
        description: 'Cloud-enabled Arduino Boards',
      },
      {
        amount: '',
        description: 'Arduino IoT Cloud Remote - Mobile App',
      },
    ],
    create_maker: [
      {
        amount: '250',
        description: 'Total Sketches',
      },
      {
        amount: '200MB',
        description: 'Storage',
      },
      {
        amount: 'unlimited',
        description: 'Compilation Time',
      },
      {
        amount: '5',
        description: 'Things',
      },
      {
        amount: '20',
        description: 'Variables',
      },
      {
        amount: '15 days',
        description: 'Cloud Data Retention',
      },
      {
        amount: '',
        description: 'Cloud API',
      },
      {
        amount: '3',
        description: 'Cloud-enabled Linux Devices',
      },
      {
        amount: '5 for each Arduino board type',
        description: 'Cloud-enabled Arduino Boards',
      },
      {
        amount: '1',
        description: 'Cloud-enabled 3rd Party Boards',
      },
      {
        amount: '',
        description: 'Custom Library editing',
      },
      {
        amount: 'ESP32/ESP8266',
        description: '3rd Party Boards support on Web Editor',
      },
      {
        amount: '',
        description: 'Arduino IoT Cloud Remote - Mobile App',
      },
    ],
    create_entry: [],
    create_makerplus: [],
    cloud_classroom: [],
  },
  features: [
    {
      title: 'Total Sketches',
      description: 'The maximum number of sketches allowed in your sketchbook.',
      amounts: {
        create_free: 'unlimited',
        create_entry: 'unlimited',
        create_maker: 'unlimited',
        create_makerplus: 'unlimited',
      },
    },
    {
      title: 'Sketch Storage',
      description: 'The amount of space to store your sketches and libraries.',
      amounts: {
        create_free: '100MB',
        create_entry: 'unlimited',
        create_maker: 'unlimited',
        create_makerplus: 'unlimited',
      },
    },
    {
      title: 'Compilation Time',
      description: 'Amount of time available to verify code and upload it to your board.',
      amounts: {
        create_free: '200 s/day',
        create_entry: 'unlimited',
        create_maker: 'unlimited',
        create_makerplus: 'unlimited',
      },
    },
    {
      title: 'Things',
      description: 'The number of connected objects available.',
      amounts: {
        create_free: '2',
        create_entry: '10',
        create_maker: '25',
        create_makerplus: '100',
      },
    },
    {
      title: 'Variables',
      description: 'Number of variables available per each Thing.',
      amounts: {
        create_free: '5',
        create_entry: '10',
        create_maker: 'unlimited',
        create_makerplus: 'unlimited',
      },
    },
    {
      title: 'Dashboards',
      description: 'Number of dashboards you can build to monitor the status of your Things and control them.',
      amounts: {
        create_free: 'unlimited',
        create_entry: 'unlimited',
        create_maker: 'unlimited',
        create_makerplus: 'unlimited',
      },
    },
    {
      title: 'Dashboard Sharing',
      description: 'Possibility to share dashboards with other Arduino users.',
      amounts: {
        create_free: false,
        create_entry: false,
        create_maker: true,
        create_makerplus: true,
      },
    },
    {
      title: 'API',
      description:
        'Possibility to use Arduino IoT Cloud backend to control your Things and devices via Arduino rest APIs or simply using sdk in javascript, python and go.',
      amounts: {
        create_free: false,
        create_entry: '1 req/s',
        create_maker: '10 req/s',
        create_makerplus: '10 req/s',
      },
    },
    {
      title: 'Webhooks',
      description:
        'Possibility to receive automated http requests and integrate Arduino IoT Cloud with other services.',
      amounts: {
        create_free: true,
        create_entry: true,
        create_maker: true,
        create_makerplus: true,
      },
    },
    {
      title: 'Over the Air Updates',
      description:
        'Possibility to upload sketches without cables using just an Internet connection. Over the Air Updates are available only for Nano 33 IoT, MKR WiFi 1010, Nano RP2040 Connect and Portenta H7.',
      amounts: {
        create_free: false,
        create_entry: true,
        create_maker: true,
        create_makerplus: true,
      },
    },
    {
      title: 'Cloud Data Retention',
      description: 'The number of days your variables historical data will be retained in Arduino IoT Cloud',
      amounts: {
        create_free: '1 day',
        create_entry: '15 days',
        create_maker: '3 months',
        create_makerplus: '1 year',
      },
    },
    {
      title: 'Custom Library editing',
      description: 'Possibility to edit existing libraries or modify your own imported ones.',
      amounts: {
        create_free: true,
        create_entry: true,
        create_maker: true,
        create_makerplus: true,
      },
    },
    {
      title: 'ESP32/ESP8266 support',
      description: 'Possibility to use ESP32/ESP8266 boards on Arduino IoT Cloud and Web Editor.',
      amounts: {
        create_free: true,
        create_entry: true,
        create_maker: true,
        create_makerplus: true,
      },
    },
    {
      title: 'LoRaWAN connectivity',
      description:
        'Possibility to find an active gateway nearby or connect a new one to \
        <a class="link" href="https://www.thethingsindustries.com/stack/" \
          target="_blank" \
          rel="noopener noreferrer">The Things Stack</a> \
      to enable the communication between your LoRaWAN devices and IoT Cloud.',
      amounts: {
        create_free: {
          value: true,
          notes: 'Included <span class="hidden-on-mobile"> in Free Plan</span> until 31/12/2021',
        },
        create_entry: true,
        create_maker: true,
        create_makerplus: true,
      },
    },
    {
      title: 'Arduino IoT Cloud Remote - Mobile App',
      description:
        'Arduino IoT Cloud Remote is a powerful companion for your Arduino IoT Cloud. Access all your IoT Cloud dashboards from your phone and control your Internet of Things projects from anywhere.\n <p><a href="https://apps.apple.com/us/app/id1514358431" target="_blank">Download iOS app</a>\n<a href="https://play.google.com/store/apps/details?id=cc.arduino.cloudiot" target="_blank">Download Android app</a></p>',
      amounts: {
        create_free: true,
        create_entry: true,
        create_maker: true,
        create_makerplus: true,
      },
    },
  ],
  planDescriptions: {
    create_free: {
      id: 'create_free',
      name: 'free',
    },
    create_maker: {
      id: 'create_maker',
      name: 'maker',
    },
    create_maker_trial: {
      id: 'create_maker_trial',
      name: 'maker trial',
    },
    create_entry: {
      id: 'create_entry',
      name: 'entry',
    },
    create_makerplus: {
      id: 'create_makerplus',
      name: 'maker plus',
    },
    cloud_classroom: {
      id: 'cloud_classroom',
      name: 'School Plan',
    },
    cloud_enterprise_monthly: {
      id: 'cloud_enterprise_monthly',
      name: 'Enterprise Plan',
    },
    cloud_enterprise_yearly: {
      id: 'cloud_enterprise_yearly',
      name: 'Enterprise Plan',
    },
  },
  featureListFootnotes: ['* LoRaWAN connectivity included in Free Plan until 31/12/2022'],
  featureList: {
    create_free: [
      '2 Things',
      'Unlimited dashboards',
      '100 Mb to store sketches',
      '1 day data retention',
      '200s/day of compilation time',
      'LoRaWAN connectivity *',
    ],
    create_entry: [
      '10 Things',
      'Unlimited dashboards',
      'Unlimited storage for sketches',
      '15 days data retention',
      'Unlimited compilation time',
      'LoRaWAN connectivity',
      'APIs',
      'Over the Air Updates',
    ],
    create_maker: [
      '25 Things',
      'Unlimited dashboards',
      'Unlimited storage for sketches',
      '3 months data retention',
      'Unlimited compilation time',
      'LoRaWAN connectivity',
      'APIs',
      'Over the Air Updates',
      'Dashboard sharing',
    ],
    create_makerplus: [
      '100 Things',
      'Unlimited dashboards',
      'Unlimited storage for sketches',
      '1 year data retention',
      'Unlimited compilation time',
      'LoRaWAN connectivity',
      'APIs',
      'Over the Air Updates',
      'Dashboard sharing',
    ],
    cloud_classroom: [],
  },
  planIds: [
    {
      id: 'free',
      title: 'Free',
    },
    {
      id: 'entry',
      title: 'Entry',
    },
    {
      id: 'maker',
      title: 'Maker',
    },
    {
      id: 'maker-plus',
      title: 'Maker Plus',
    },
    {
      id: 'cloud_classroom',
      title: 'Cloud Classroom',
    },
  ],
};

export default config;
