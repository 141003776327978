import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import AbsoluteSpinner from '../../../common/AbsoluteSpinner';

import './ProductDetails.scss';
import { currencyCountries } from '../../../../currencyCountries';
import { OpenWarningNotification } from '../../../common/Toast/Toast';
import SummaryCtaButtons from './SummaryCtaButtons';

import {
  EDUCATION_PURCHASE,
  EDUCATION_PURCHASE_INSTITUTION,
  CLOUD_PURCHASE,
  CREATE_MAKER_MONTHLY,
  CREATE_MAKERPLUS_MONTHLY,
  CREATE_ENTRY_YEARLY,
  CREATE_MAKER_YEARLY,
  CREATE_MAKERPLUS_YEARLY,
  CERTIFICATION_STARTERKIT,
  CERTIFICATION_PURCHASE,
  CERTIFICATION_PLAN,
  JUNIOR_CERTIFICATION_PRODUCT,
  ENTERPRISE_PURCHASE,
  ENTERPRISE_PRICE_ADDON_X8_MONTHLY,
  ENTERPRISE_PRICE_ADDON_X8_YEARLY,
  CERTIFICATION_PURCHASE_EXAM_TYPE,
  ENTERPRISE_PRICE_ADDON3,
  ENTERPRISE_PRICE_ADDON4,
  ENTERPRISE_PRICE_THING_MONTHLY,
  ENTERPRISE_PRICE_THING_YEARLY,
  FREQUENCY_YEARLY,
  ENTERPRISE_ADDON_X8_YEARLY,
  ENTERPRISE_ADDON_X8_MONTHLY,
} from '../../../../utils/utilities';

/*
 * We have to manage discount code and taxes, refactoring it
 */

function ProductDetails(props) {
  const {
    cart,
    card,
    taxes,
    coupon,
    profile,
    certificates,
    subscription,
    enterprisePlanAddons,
    enterprisePlanThings,
    enterprisePlanFrequency,
    calculateTaxes,
    eduInstitutionCtaDisabled,
    eduConfigurePlanDisabled,
    enterpriseConfigurePlanDisabled,
    billingDisabled,
    handleEditBillingInfo,
    cloudPlanDisabled,
    paymentInfoDisabled,
    newCard,
    purchaseInProgress,
    x8DevicesNumber,
    deleteCouponBanner,
    handleAlternativePaymentSubmit,
    setZapierPrice,
    certificationType,
    setFinalPrice,
  } = props;

  const currency = '$';
  let freeTrialBanner;
  let nextPayment;
  let nextEnterprisePayment;
  let priceDescription;
  let vatDisclaimer;

  const institutionTab = props.history.location.pathname === EDUCATION_PURCHASE_INSTITUTION;

  //const productPrice = props.quantity ? props.price * props.quantity : props.price;
  const [productPrice, setProductPrice] = useState(0);
  const [total, setTotal] = useState(Math.round(productPrice * 100) / 100);
  const [cloudDefaultPrice, setCloudDefaultPrice] = useState(Math.round(productPrice * 100) / 100);
  const [discount, setDiscount] = useState(0);
  const [discountTooltip, setDiscountTooltip] = useState(null);
  const [discountFirstYear, setDiscountFirstYear] = useState(false);
  const [tax, setTax] = useState(0);
  const [flagNoCumulative, setFlagNoCumulative] = useState(true);

  const [discountPercent, setDiscountPercent] = useState(0); // Edu coupon in %
  const [discountAmount, setDiscountAmount] = useState(0); // Edu coupon in $

  const eduSubscriptionId = subscription.byPlan.cloud_classroom;
  const [educationPlanRenewalDate, setEducationPlanRenewalDate] = useState('');

  const [eduPriceFirstPurchase, setEduPriceFirstPurchase] = useState(0);

  const [enterprisePrice, setEnterprisePrice] = useState(0);
  const [enterpriseTodayPrice, setEnterpriseTodayPrice] = useState(0);

  const [addonX8, setAddonX8] = useState(false);

  //no data - we'll insert it in a second release
  const [addons3, setAddons3] = useState(false);
  const [addons4, setAddons4] = useState(false);

  const lastTotalUpgradePrice = useMemo(() => {
    return subscription?.lastTotalUpgradePrice.total;
  }, [subscription?.lastTotalUpgradePrice.total]);

  const cloudPlanTitle = useMemo(() => {
    if (props.cloudPlanType === CREATE_MAKERPLUS_MONTHLY) return 'Maker Plus Plan';
    else if (props.cloudPlanType === CREATE_ENTRY_YEARLY) return 'Entry Plan';
    else if (props.cloudPlanType === CREATE_MAKERPLUS_YEARLY) return 'Maker Plus Plan';
    else return 'Maker Plan';
  }, [props.cloudPlanType]);

  useEffect(() => {
    if (eduSubscriptionId) {
      const subDate = subscription.byId[eduSubscriptionId].renewal_date;
      setEducationPlanRenewalDate(moment(subDate).format('MMMM DD, YYYY'));
    }
  }, [eduSubscriptionId, subscription]);

  const educationPath = props.match.path === EDUCATION_PURCHASE;
  const cloudPath = props.match.path === CLOUD_PURCHASE;
  const certificationPath = props.match.path === CERTIFICATION_PURCHASE;
  const enterprisePath = props.match.path === ENTERPRISE_PURCHASE;
  const showCertificationPrice = props.history.location.pathname !== CERTIFICATION_PURCHASE_EXAM_TYPE;

  if (
    props.profile.data.billing &&
    props.profile.data.billing.type !== 'P' &&
    props.profile.data.billing.vat &&
    !props.profile.data.billing.vat_valid &&
    currencyCountries.euCountries.findIndex((code) => code === props.profile.data.billing.country) > -1 &&
    props.profile.data.billing.country !== 'IN'
  ) {
    vatDisclaimer = (
      <div className="vat-disclaimer">
        <div>
          The <strong>Tax Number</strong> you entered <strong>is not valid</strong>, please verify the validity through
          the VIES system.
          <span>
            {' '}
            VAT <strong>will be charged</strong> to your order.
          </span>
        </div>
      </div>
    );
  } else if (
    props.profile.data.billing &&
    props.profile.data.billing.type !== 'P' &&
    props.profile.data.billing.vat &&
    !props.profile.data.billing.vat_valid &&
    props.profile.data.billing.country === 'IN'
  ) {
    vatDisclaimer = (
      <div className="vat-disclaimer">
        <strong>Taxes</strong> will be charge to you order.
      </div>
    );
  }

  if (educationPlanRenewalDate !== '') {
    nextPayment = <span className="next-payment">Next payment on {educationPlanRenewalDate}</span>;
  }

  if (props.freeTrial.trial_available) {
    const freeTrialFinalDate = moment(Date.now()).add(props.freeTrial.trial_period_days, 'days').format('Do MMM YYYY');
    freeTrialBanner = (
      <div className="credit-banner">
        <div className="title">
          {`Your free ${props.freeTrial.trial_period_days} day trial ends on ${freeTrialFinalDate}.`}
        </div>
        <div className="description">
          {`If you don’t cancel your free trial by that date, we’ll charge you $${productPrice.toFixed(
            2
          )} monthly until you cancel your plan.`}
        </div>
      </div>
    );

    priceDescription = (
      <div className="price-description">
        <span className="label">
          {props.plan.priceTrialDescription}
          {props.freeTrial.trial_period_days}
          {' days'}
        </span>
      </div>
    );
  }

  useEffect(() => {
    if (props.coupon.data && props.coupon.data.valid) {
      setDiscountFirstYear(true);
      if (props.plan.promotion && flagNoCumulative) {
        if (!educationPath)
          OpenWarningNotification('All offers are not cumulative with other discounts or promotions already running');
        setFlagNoCumulative(false);
      }
      if (props.coupon.data.percent_off && props.coupon.data.percent_off > 0) {
        setDiscountPercent(props.coupon.data.percent_off);
      }
      if (props.coupon.data.amount_off && props.coupon.data.amount_off > 0) {
        setDiscountAmount(props.coupon.data.amount_off / 100);
        if (enterprisePath) {
          setTax(0);
        }
      }
      if (props.coupon.data.percent_off === 100) {
        setTax(0);
        setTotal(Math.round(productPrice * 100) / 100);
        setCloudDefaultPrice(Math.round(productPrice * 100) / 100);
        setProductPrice(0);
      } else if (props.coupon.data.amount_off) {
        calculateTaxes(enterpriseTodayPrice < 0 ? 0 : enterpriseTodayPrice); // - props.coupon?.data.amount_off / 100
      } else {
        setDiscountTooltip(null);
        const calculatedDiscount = props.coupon.data.percent_off
          ? Math.round(productPrice * props.coupon.data.percent_off) / 100
          : props.coupon.data.amount_off / 100;
        setDiscount(calculatedDiscount);
        const newTotal = Math.round((productPrice - calculatedDiscount) * 100) / 100;
        calculateTaxes(newTotal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon, coupon.data, props.profile]);

  // If VAT and not Personal, we don't pay tax.
  useEffect(() => {
    if (
      props.profile.data.billing &&
      props.profile.data.billing.type !== 'P' &&
      props.profile.data.billing.vat &&
      props.profile.data.billing.vat_valid
    ) {
      setTax(0);
    }
  }, [props.profile.data.billing]);

  useEffect(() => {
    if (
      (!coupon.data || !coupon.data.valid) &&
      profile.data &&
      profile.data.billing &&
      !educationPath &&
      !enterprisePath
    ) {
      calculateTaxes(Math.round((productPrice - discount) * 100) / 100);
    }
  }, [profile, coupon, calculateTaxes, productPrice, discount, educationPath, enterprisePath]);

  useEffect(() => {
    if (deleteCouponBanner) {
      setDiscountFirstYear(false);
    }
  }, [deleteCouponBanner]);

  /* If certificate, users pay no tax */
  useEffect(() => {
    if (certificates && certificates.length > 0) {
      certificates.forEach((certificate) => {
        if (certificate.certificate_type === 'tax_exempt') {
          setTax(0);
          setEduTax(0);
        }
      });
    }
  }, [certificates]);

  /* If Error on API, users pay no tax */
  useEffect(() => {
    if (!taxes.inProgress && taxes.lastUpdate && taxes.error) {
      setTax(0);
      setEduTax(0);
    }
  }, [taxes, enterprisePath]);

  useEffect(() => {
    const percentOff100 = props.coupon.data?.percent_off === 100;
    if (props.cloudPlanType === CREATE_MAKER_MONTHLY) {
      if (props.coupon.data && props.coupon.data.percent_off > 0 && props.coupon?.data?.valid)
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(6.99 + tax - ((6.99 + tax) * props.coupon.data.percent_off) / 100);
        }
      else setTotal(6.99 + tax);
      setProductPrice(6.99);
      setCloudDefaultPrice(6.99 + tax);
      props.calculateTaxes(6.99);
    }
    if (props.cloudPlanType === CREATE_MAKERPLUS_MONTHLY) {
      if (props.coupon.data && props.coupon?.data?.percent_off > 0 && props.coupon?.data?.valid) {
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(23.99 + tax - ((23.99 + tax) * props.coupon.data.percent_off) / 100);
        }
      } else {
        setTotal(23.99 + tax);
      }
      setProductPrice(23.99);
      setCloudDefaultPrice(23.99 + tax);
      props.calculateTaxes(23.99);
    }
    if (props.cloudPlanType === CREATE_ENTRY_YEARLY) {
      if (props.coupon.data && props.coupon?.data?.percent_off > 0 && props.coupon?.data?.valid) {
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(23.88 + tax - ((23.88 + tax) * props.coupon.data.percent_off) / 100);
        }
      } else setTotal(23.88 + tax);
      setProductPrice(23.88);
      setCloudDefaultPrice(23.88 + tax);
      props.calculateTaxes(23.88);
    }
    if (props.cloudPlanType === CREATE_MAKER_YEARLY) {
      if (props.coupon.data && props.coupon?.data.percent_off > 0 && props.coupon?.data?.valid) {
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(71.88 + tax - ((71.88 + tax) * props.coupon.data.percent_off) / 100);
        }
      } else setTotal(71.88 + tax);
      setProductPrice(71.88);
      setCloudDefaultPrice(71.88 + tax);
      props.calculateTaxes(71.88);
    }
    if (props.cloudPlanType === CREATE_MAKERPLUS_YEARLY) {
      if (props.coupon.data && props.coupon?.data.percent_off > 0 && props.coupon?.data?.valid) {
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(239.88 + tax - ((239.88 + tax) * props.coupon.data.percent_off) / 100);
        }
      } else setTotal(239.88 + tax);
      setProductPrice(239.88);
      setCloudDefaultPrice(239.88 + tax);
      props.calculateTaxes(239.88);
    }
    if (certificationPath && certificationType === CERTIFICATION_PLAN) {
      if (props.coupon.data && props.coupon?.data.percent_off > 0 && props.coupon?.data?.valid) {
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(certificationTotal + tax - ((certificationTotal + tax) * props.coupon.data.percent_off) / 100);
        }
      } else setTotal(certificationTotal + tax);
      setProductPrice(certificationTotal);
      setCloudDefaultPrice(certificationTotal + tax);
      props.calculateTaxes(certificationTotal);
    }
    if (certificationPath && certificationType === JUNIOR_CERTIFICATION_PRODUCT) {
      if (props.coupon.data && props.coupon?.data.percent_off > 0 && props.coupon?.data?.valid) {
        if (percentOff100) {
          setTotal(0);
        } else {
          setTotal(
            juniorCertificationTotal + tax - ((juniorCertificationTotal + tax) * props.coupon.data.percent_off) / 100
          );
        }
      } else setTotal(juniorCertificationTotal + tax);
      setProductPrice(juniorCertificationTotal);
      setCloudDefaultPrice(juniorCertificationTotal + tax);
      props.calculateTaxes(juniorCertificationTotal);
    }
    if (enterprisePath && props.coupon.data && props.coupon?.data.percent_off === 100 && props.coupon?.data?.valid) {
      setEnterpriseTodayPrice(0);
      setTax(0);
    }
    if (cloudPath && props.coupon?.data?.valid && props.coupon?.data.amount_off) {
      setTotal(total - (props.coupon?.data.amount_off / 100).toFixed(2));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    certificationType,
    props.cloudPlanFrequency,
    props.cloudPlanType,
    tax,
    props.coupon.data,
    props.examCodes,
    props.juniorExamCodes,
  ]);

  useEffect(() => {
    if (
      enterprisePath &&
      props.coupon.data &&
      props.coupon?.data.amount_off &&
      props.coupon?.data?.valid &&
      enterpriseTodayPrice === enterprisePrice
    ) {
      setEnterpriseTodayPrice(enterpriseTodayPrice - props.coupon?.data.amount_off / 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprisePath, props.coupon.data]);

  useEffect(() => {
    if (enterprisePath) {
      setAddonX8(false);
      setAddons3(false);
      setAddons4(false);
      let updatedEnterprisePrice = 0;
      if (
        enterprisePlanAddons.includes(ENTERPRISE_ADDON_X8_MONTHLY) ||
        enterprisePlanAddons.includes(ENTERPRISE_ADDON_X8_YEARLY)
      ) {
        updatedEnterprisePrice =
          enterprisePlanFrequency === FREQUENCY_YEARLY
            ? updatedEnterprisePrice + ENTERPRISE_PRICE_ADDON_X8_YEARLY * x8DevicesNumber
            : updatedEnterprisePrice + ENTERPRISE_PRICE_ADDON_X8_MONTHLY * x8DevicesNumber;
        setAddonX8(true);
      }

      const thingsPrice =
        enterprisePlanFrequency === FREQUENCY_YEARLY
          ? enterprisePlanThings * ENTERPRISE_PRICE_THING_YEARLY
          : enterprisePlanThings * ENTERPRISE_PRICE_THING_MONTHLY;

      setEnterprisePrice(updatedEnterprisePrice + thingsPrice);
      setZapierPrice(updatedEnterprisePrice + thingsPrice);
      setEnterpriseTodayPrice(updatedEnterprisePrice + thingsPrice);
      if (!isNaN(thingsPrice)) {
        calculateTaxes(updatedEnterprisePrice + thingsPrice);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    x8DevicesNumber,
    enterprisePlanAddons,
    enterprisePath,
    enterprisePlanThings,
    calculateTaxes,
    enterprisePlanFrequency,
    props.profile.data.billing,
  ]);

  useEffect(() => {
    if ((cloudPath || certificationPath || enterprisePath) && props?.taxes?.data?.taxes >= 0) {
      setTax(props?.taxes?.data?.taxes);
    } else if (educationPath && props?.taxes?.data?.taxes) {
      setEduTax(props?.taxes?.data?.taxes);
    }
    // if (props?.taxes?.data?.taxes === 0 || discountAmount !== 0) {
    //   setTax(0);
    //   setEduTax(0);
    // }
  }, [
    props?.taxes?.data?.taxes,
    cloudPath,
    certificationPath,
    enterprisePath,
    educationPath,
    profile.data.billing.country,
    discountAmount,
  ]);

  let priceSection = null;
  let eduPriceSeats = null;
  let certificationPrice = null;
  let enterprisePriceSection = null;
  let juniorCertificationPrice = null;

  const quantity = props.quantity;

  const [eduTax, setEduTax] = useState(0);
  const seats20 = quantity <= 50 ? quantity : 50;
  const seats18 = quantity > 50 ? (quantity - 50 <= 50 ? quantity - 50 : 50) : 0;
  const seats15 = quantity > 100 ? quantity - 100 : 0;

  const eduCloudClassroomTotal = useMemo(() => {
    return seats20 * 20 + seats18 * 18 + seats15 * 15;
  }, [seats20, seats18, seats15]);

  const certification30 = props.examCodes <= 99 ? props.examCodes : 99;
  const certification28e5 = props.examCodes > 99 ? (props.examCodes - 99 <= 150 ? props.examCodes - 99 : 150) : 0;
  const certification27 = props.examCodes > 249 ? props.examCodes - 249 : 0;

  const certificationTotal = useMemo(() => {
    return certification30 * 30 + certification28e5 * 28.5 + certification27 * 27;
  }, [certification30, certification28e5, certification27]);

  const juniorCertificationTotal = useMemo(() => {
    return certification30 * 20 + certification28e5 * 19 + certification27 * 18;
  }, [certification30, certification28e5, certification27]);

  const todaysPrice = useMemo(() => {
    let result;

    if (educationPath) {
      if (props.isEduUpgrading && !isNaN(lastTotalUpgradePrice / 100)) {
        result = (lastTotalUpgradePrice / 100).toFixed(2);
      } else if (discountPercent > 0 || discountAmount > 0) {
        if (discountPercent > 0) {
          result = Math.max(
            0,
            eduCloudClassroomTotal + eduTax - ((eduCloudClassroomTotal + eduTax) * discountPercent) / 100
          ).toFixed(2);
        } else {
          result = Math.max(0, eduCloudClassroomTotal + eduTax - discountAmount).toFixed(2);
        }
      } else {
        result = (eduCloudClassroomTotal + eduTax).toFixed(2);
      }
    } else if (cloudPath && props.isCloudUpgrading && !isNaN(lastTotalUpgradePrice / 100)) {
      if (lastTotalUpgradePrice >= 0) {
        result = (lastTotalUpgradePrice / 100).toFixed(2);
      } else {
        result = 0;
      }
    } else if (enterprisePath) {
      if (enterpriseTodayPrice + tax < 0) {
        result = 0;
      } else {
        result = (enterpriseTodayPrice + tax).toFixed(2);
      }
    } else if (certificationPath && !showCertificationPrice) {
      result = 0;
    } else {
      result = total.toFixed(2);
    }

    return result;
  }, [
    certificationPath,
    cloudPath,
    discountAmount,
    discountPercent,
    eduCloudClassroomTotal,
    eduTax,
    educationPath,
    enterprisePath,
    enterpriseTodayPrice,
    lastTotalUpgradePrice,
    props.isCloudUpgrading,
    props.isEduUpgrading,
    showCertificationPrice,
    tax,
    total,
  ]);

  useEffect(() => setFinalPrice(todaysPrice), [setFinalPrice, todaysPrice]);

  useEffect(() => {
    if (educationPath && props.profile.data.billing) {
      calculateTaxes(eduCloudClassroomTotal);
    }
  }, [eduCloudClassroomTotal, quantity, educationPath, calculateTaxes, props.profile.data.billing]);

  useEffect(() => {
    if (certificates && certificates.length > 0) {
      certificates.forEach((certificate) => {
        if (certificate.certificate_type === 'tax_exempt') {
          setEduTax(0);
        } else {
          setEduTax((eduCloudClassroomTotal * props?.taxes?.data?.percentage) / 100);
        }
      });
    }
  }, [eduCloudClassroomTotal, props?.taxes?.data?.percentage, props.profile.data.billing.country, certificates]);

  //Manage price for edu Plans first purchase. Is sended to SummaryCta for purchase with no CC.
  useEffect(() => {
    if (educationPath && props.coupon?.data?.valid) {
      if (
        props.coupon?.data?.amount_off &&
        eduPriceFirstPurchase !== eduCloudClassroomTotal + eduTax - discountAmount
      ) {
        setEduPriceFirstPurchase(eduCloudClassroomTotal + eduTax - discountAmount);
      } else if (
        props.coupon?.data?.percent_off &&
        eduPriceFirstPurchase !== (eduCloudClassroomTotal + eduTax * discountPercent) / 100
      ) {
        if (props.coupon?.data?.percent_off === 100) setEduPriceFirstPurchase(0);
        else setEduPriceFirstPurchase((eduCloudClassroomTotal + eduTax * discountPercent) / 100);
      }
    }
  }, [
    eduPriceFirstPurchase,
    eduCloudClassroomTotal,
    eduTax,
    discountAmount,
    discountPercent,
    props.coupon?.data?.amount_off,
    props.coupon?.data?.percent_off,
    educationPath,
    props.coupon?.data?.valid,
  ]);

  useEffect(() => {
    if (educationPath) setZapierPrice(eduCloudClassroomTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eduCloudClassroomTotal, educationPath]);

  if (educationPath) {
    eduPriceSeats = (
      <>
        <div className="price price-row price-row-spacing">
          <span className="label">
            {quantity <= 50 ? quantity : 50} members x {currency} 20.00
          </span>
          <span className="value">
            {currency}
            {quantity <= 50 ? quantity * 20 : 1000}
          </span>
        </div>
        {quantity > 50 && (
          <div className="price price-row price-row-spacing">
            <span className="label">
              {quantity - 50 <= 50 ? quantity - 50 : 50} members x {currency} 18.00
            </span>
            <span className="value">
              {currency}
              {quantity - 50 <= 50 ? (quantity - 50) * 18 : 900}
            </span>
          </div>
        )}
        {quantity > 100 && (
          <div className="price price-row price-row-spacing">
            <span className="label">
              {quantity - 100} members x {currency} 15.00
            </span>
            <span className="value">
              {currency}
              {(quantity - 100) * 15}
            </span>
          </div>
        )}
      </>
    );
  }

  if (certificationPath && certificationType === CERTIFICATION_PLAN) {
    certificationPrice = (
      <>
        <div className="price price-row price-row-spacing">
          <span className="label">
            {props.examCodes <= 99 ? props.examCodes : 99} exam codes x {currency} 30.00
          </span>
          <span className="value">
            {currency}
            {props.examCodes <= 99 ? props.examCodes * 30 : 2970}
          </span>
        </div>
        {props.examCodes > 99 && (
          <div className="price price-row price-row-spacing">
            <span className="label">
              {props.examCodes - 99 <= 150 ? props.examCodes - 99 : 150} exam codes x {currency} 28.50
            </span>
            <span className="value">
              {currency}
              {props.examCodes - 99 <= 150 ? ((props.examCodes - 99) * 28.5).toFixed(2) : (4246.5).toFixed(2)}
            </span>
          </div>
        )}
        {props.examCodes > 249 && (
          <div className="price price-row price-row-spacing">
            <span className="label">
              {props.examCodes - 249} exam codes x {currency} 27.00
            </span>
            <span className="value">
              {currency}
              {(props.examCodes - 249) * 27}
            </span>
          </div>
        )}
      </>
    );
  }
  if (certificationPath && certificationType === JUNIOR_CERTIFICATION_PRODUCT) {
    juniorCertificationPrice = (
      <>
        <div className="price price-row price-row-spacing">
          <span className="label">
            {props.examCodes <= 99 ? props.examCodes : 99} exam codes x {currency} 20.00
          </span>
          <span className="value">
            {currency}
            {props.examCodes <= 99 ? props.examCodes * 20 : 1980}
          </span>
        </div>
        {props.examCodes > 99 && (
          <div className="price price-row price-row-spacing">
            <span className="label">
              {props.examCodes - 99 <= 150 ? props.examCodes - 99 : 150} exam codes x {currency} 19
            </span>
            <span className="value">
              {currency}
              {props.examCodes - 99 <= 150 ? (props.examCodes - 99) * 19 : 2850}
            </span>
          </div>
        )}
        {props.examCodes > 249 && (
          <div className="price price-row price-row-spacing">
            <span className="label">
              {props.examCodes - 249} exam codes x {currency} 18
            </span>
            <span className="value">
              {currency}
              {(props.examCodes - 249) * 18}
            </span>
          </div>
        )}
      </>
    );
  }

  if (enterprisePath) {
    const thingsPrice =
      enterprisePlanFrequency === FREQUENCY_YEARLY
        ? enterprisePlanThings * ENTERPRISE_PRICE_THING_YEARLY
        : enterprisePlanThings * ENTERPRISE_PRICE_THING_MONTHLY;

    enterprisePriceSection = (
      <>
        <div className="price price-row price-margin-top">
          <span className="label">
            {enterprisePlanThings} Things, {enterprisePlanFrequency} billing
          </span>
          <span className="value">
            {currency} {thingsPrice}
          </span>
        </div>
        {addonX8 && (
          <>
            <div className="price price-row price-margin-top">
              <span className="label">Portenta X8 Board Manager</span>
              <span className="value">
                {currency}
                {enterprisePlanFrequency === FREQUENCY_YEARLY
                  ? ENTERPRISE_PRICE_ADDON_X8_YEARLY * x8DevicesNumber
                  : ENTERPRISE_PRICE_ADDON_X8_MONTHLY * x8DevicesNumber}
              </span>
            </div>
            <span className="price price-row price-margin-top sub-price">{x8DevicesNumber} devices</span>
          </>
        )}
        {addons3 && (
          <div className="price price-row price-margin-top">
            <span className="label">Integration 3</span>
            <span className="value">
              {currency} {ENTERPRISE_PRICE_ADDON3}
            </span>
          </div>
        )}
        {addons4 && (
          <div className="price price-row price-margin-top">
            <span className="label">Integration 4</span>
            <span className="value">
              {currency} {ENTERPRISE_PRICE_ADDON4.toFixed(2)}
            </span>
          </div>
        )}
      </>
    );
  }

  if (props.taxes.inProgress) {
    priceSection = (
      <div className="details-spinner-wrapper">
        <AbsoluteSpinner height={'100%'} white />
      </div>
    );
  } else {
    priceSection = (
      <div className="price-section">
        <div className="detail-section">
          {!institutionTab && (
            <>
              {educationPath ? (
                <span>{eduPriceSeats}</span>
              ) : certificationPath && certificationType === CERTIFICATION_PLAN && showCertificationPrice ? (
                <span>{certificationPrice}</span>
              ) : certificationPath && certificationType === JUNIOR_CERTIFICATION_PRODUCT && showCertificationPrice ? (
                <span>{juniorCertificationPrice}</span>
              ) : certificationPath && !showCertificationPrice ? null : !enterprisePath ? (
                <div className="price price-row price-margin-top">
                  <span className="label">
                    {props.cloudPlanType !== '' &&
                      props.cloudPlanFrequency !== '' &&
                      cloudPlanTitle + ', ' + props.cloudPlanFrequency + ' billing'}
                  </span>
                  <span className="value">
                    {currency} {productPrice.toFixed(2)}
                  </span>
                </div>
              ) : (
                <span>{enterprisePriceSection}</span>
              )}
              {!educationPath && !cloudPath && priceDescription}
              <div className="tax price-row">
                <span className="label">{'Taxes'}</span>
                {educationPath ? (
                  <span className="value">{!eduTax || eduTax === 0 ? '-' : currency + ' ' + eduTax}</span>
                ) : certificationPath && !showCertificationPrice ? (
                  <span className="value">{'-'}</span>
                ) : (
                  <span className="value">{tax === 0 ? '—' : currency.concat(' ').concat(tax.toFixed(2))}</span>
                )}
              </div>
            </>
          )}
          {props.coupon?.data?.valid && props.coupon?.data?.percent_off && (
            <div className="discount-title">
              <span className="label">{'Discount*'}</span>
              <span className="value">{discountPercent} %</span>
            </div>
          )}
          {props.coupon?.data?.valid && props.coupon?.data?.amount_off && (
            <div className="discount-title">
              <span className="label">{'Discount*'}</span>
              <span className="value">{discountAmount} $</span>
            </div>
          )}

          <div className="price-title">
            <span className="label"></span> <span className="value">{'total'}</span>
          </div>
        </div>
        {cloudPath && props.cloudPlanFrequency !== '' && (
          <div className="payment price-row">
            <span className="label">{props.cloudPlanFrequency + ' payment'}</span>
            <span className={classnames('value')}>
              {currency} {cloudDefaultPrice.toFixed(2)}
            </span>
          </div>
        )}
        {educationPath && (
          <div className="payment price-row">
            <span className="label">{'Annual payment'}</span>
            {!institutionTab ? (
              <span className={classnames('value')}>
                {currency} {(eduCloudClassroomTotal + eduTax).toFixed(2)}
              </span>
            ) : (
              <span>-</span>
            )}
            {nextPayment}
          </div>
        )}
        {enterprisePath && (
          <div className="payment price-row">
            <span className="label">
              {enterprisePlanFrequency === FREQUENCY_YEARLY ? 'Yearly payment' : 'Monthly payment'}
            </span>
            <span className={classnames('value')}>
              {currency} {(enterprisePrice + tax).toFixed(2)}
            </span>
            {nextEnterprisePayment}
          </div>
        )}
        <div className="payment price-row">
          <span className="label">
            {certificationPath ? 'Total payment' : "Today's payment"}
            {props.isCloudUpgrading && !props.isCloudDowngrading && !certificationPath && '*'}
            {props.isEduUpgrading && props.actualSubscription?.quantity < quantity && '*'}
          </span>
          {!institutionTab && !subscription.readList.inProgress ? (
            <span className={classnames('value')}>
              {currency} {todaysPrice}
            </span>
          ) : (
            <span className="value">{'-'}</span>
          )}
          {discountTooltip}
        </div>
        {discountFirstYear && (discountPercent > 0 || discountAmount) && !certificationPath && (
          <div className="credit-banner">
            * The discount will only apply to the <strong>first year</strong> of your subscription
          </div>
        )}
        {props.isEduUpgrading && props.actualSubscription?.quantity < quantity && (
          <div className="credit-banner">
            *Price for <strong>{quantity - props.actualSubscription.quantity}</strong> new members for the remaining{' '}
            <strong>
              {moment(subscription.byId[eduSubscriptionId].renewal_date).diff(new Date(), 'months')} months{' '}
            </strong>
            of this payment period
          </div>
        )}
        {props.isCloudUpgrading && !props.isCloudDowngrading && props.plan.id !== CERTIFICATION_STARTERKIT && (
          <div className="credit-banner">
            *Price difference between the selected plan and your current plan for the remaining time of your payment
            period.
          </div>
        )}
        {vatDisclaimer}
        {freeTrialBanner}
      </div>
    );
  }

  const cta = (
    <SummaryCtaButtons
      coupon={props.coupon}
      match={props.match}
      profile={props.profile}
      history={props.history}
      cart={cart}
      card={card}
      eduInstitutionCtaDisabled={eduInstitutionCtaDisabled}
      eduConfigurePlanDisabled={eduConfigurePlanDisabled}
      enterpriseConfigurePlanDisabled={enterpriseConfigurePlanDisabled}
      billingDisabled={billingDisabled}
      handlePlanSubmit={props.onPlanSubmit}
      handleEditBillingInfo={handleEditBillingInfo}
      isEduUpgrading={props.isEduUpgrading}
      isCloudUpgrading={props.isCloudUpgrading}
      progress={props.progress}
      terms={props.terms}
      paymentProgress={props.paymentProgress}
      fullValidCoupon={props.fullValidCoupon}
      cloudPlanDisabled={cloudPlanDisabled}
      paymentInfoDisabled={paymentInfoDisabled}
      newCard={newCard}
      enterpriseTodayPrice={enterpriseTodayPrice}
      purchaseInProgress={purchaseInProgress}
      educationPath={educationPath}
      eduTodayPrice={eduPriceFirstPurchase}
      handleAlternativePaymentSubmit={handleAlternativePaymentSubmit}
      certificationType={certificationType}
    />
  );

  return (
    <>
      <div className="product-details">
        <h3 className="title">{'Purchase summary'}</h3>
        {educationPath && <div className="sub-title">Arduino Cloud, School plan</div>}
        {certificationPath && certificationType === CERTIFICATION_PLAN && (
          <div className="sub-title">Arduino Certification</div>
        )}
        {certificationPath && certificationType === JUNIOR_CERTIFICATION_PRODUCT && (
          <div className="sub-title">Arduino Junior Certification</div>
        )}
        {enterprisePath && <div className="sub-title">Arduino Cloud, Enterprise plan</div>}
        <hr className="hr-style" />
        {priceSection}
        <div className="product-details__button">{cta}</div>
        {/* <div className="arduino-form">{form}</div> */}
      </div>
    </>
  );
}

ProductDetails.propTypes = {
  cart: PropTypes.object,
  card: PropTypes.object,
  plan: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  freeTrial: PropTypes.object,
  coupon: PropTypes.object,
  verifyCoupon: PropTypes.func,
  taxes: PropTypes.object,
  quantity: PropTypes.number,
  price: PropTypes.number,
  examCodes: PropTypes.number,
  juniorExamCodes: PropTypes.number,
  calculateTaxes: PropTypes.func,
  progress: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  onInstitutionSubmit: PropTypes.func,
  onBillingSubmit: PropTypes.func,
  onPlanSubmit: PropTypes.func,
  subscription: PropTypes.object,
  isEduUpgrading: PropTypes.bool,
  changeQuantityOfCurrentInstitution: PropTypes.func,
  organization: PropTypes.object,
  actualSubscription: PropTypes.object,
  certificates: PropTypes.array,
  isCloudUpgrading: PropTypes.bool,
  cloudPlanFrequency: PropTypes.string,
  cloudPlanType: PropTypes.string,
  isCloudDowngrading: PropTypes.bool,
  errors: PropTypes.object,
  enterprisePlanAddons: PropTypes.array,
  enterprisePlanThings: PropTypes.number,
  enterprisePlanFrequency: PropTypes.string,
  eduInstitutionCtaDisabled: PropTypes.bool,
  handlePlanSubmit: PropTypes.func,
  handleEditBillingInfo: PropTypes.func,
  billingDisabled: PropTypes.bool,
  paymentProgress: PropTypes.bool,
  terms: PropTypes.bool,
  fullValidCoupon: PropTypes.bool,
  cloudPlanDisabled: PropTypes.bool,
  paymentInfoDisabled: PropTypes.bool,
  newCard: PropTypes.bool,
  purchaseInProgress: PropTypes.bool,
  x8DevicesNumber: PropTypes.number,
  deleteCouponBanner: PropTypes.bool,
  handleAlternativePaymentSubmit: PropTypes.func,
  setZapierPrice: PropTypes.func,
  certificationType: PropTypes.string,
};

export default ProductDetails;
