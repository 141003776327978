import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import './ProductDetails.scss';
import {
  EDUCATION_PURCHASE_INSTITUTION,
  EDUCATION_PURCHASE_PLAN,
  EDUCATION_PURCHASE_BILLING,
  EDUCATION_PURCHASE_PAYMENT,
  CLOUD_PURCHASE_PLAN,
  CLOUD_PURCHASE_BILLING,
  CLOUD_PURCHASE_PAYMENT_INFO,
  CLOUD_PURCHASE_PAYMENT,
  CERTIFICATION_PURCHASE_EXAM_TYPE,
  CERTIFICATION_PURCHASE_PLAN,
  CERTIFICATION_PURCHASE_BILLING,
  CERTIFICATION_PURCHASE_PAYMENT,
  CERTIFICATION_STARTERKIT,
  EDUCATION_PURCHASE_PAYMENT_INFO,
  ENTERPRISE_PURCHASE_PLAN,
  ENTERPRISE_PURCHASE_ADDONS,
  ENTERPRISE_PURCHASE_BILLING,
  ENTERPRISE_PURCHASE_PAYMENT,
  ENTERPRISE_PURCHASE_ALTERNATIVE_PAYMENT,
  EDUCATION_PURCHASE_ALTERNATIVE_PAYMENT,
  JUNIOR_CERTIFICATION_PURCHASE_PLAN,
  JUNIOR_CERTIFICATION_PURCHASE_BILLING,
  JUNIOR_CERTIFICATION_PURCHASE_PAYMENT,
  JUNIOR_CERTIFICATION_PRODUCT,
} from '../../../../utils/utilities';

function SummaryCtaButtons(props) {
  const {
    match,
    card,
    cart,
    eduInstitutionCtaDisabled,
    isEduUpgrading,
    progress,
    eduConfigurePlanDisabled,
    enterpriseConfigurePlanDisabled,
    handlePlanSubmit,
    billingDisabled,
    paymentProgress,
    fullValidCoupon,
    terms,
    isCloudUpgrading,
    cloudPlanDisabled,
    paymentInfoDisabled,
    profile,
    history,
    newCard,
    coupon,
    enterpriseTodayPrice,
    purchaseInProgress,
    educationPath,
    eduTodayPrice,
    handleAlternativePaymentSubmit,
    certificationType,
  } = props;
  const location = useLocation();
  let cta = null;
  const [newBillingInformation, setNewBillingInformation] = useState(false);

  const isCertification = useMemo(
    () => cart.product === CERTIFICATION_STARTERKIT || card.product === JUNIOR_CERTIFICATION_PRODUCT,
    [card.product, cart.product]
  );

  useEffect(() => {
    if (
      profile.data.billing.name !== '' &&
      profile.data.billing.type !== '' &&
      profile.data.billing.street1 !== '' &&
      profile.data.billing.postcode !== '' &&
      profile.data.billing.country !== '' &&
      newBillingInformation
    ) {
      history.push(`${match.path}/payment`);
    }
  }, [
    history,
    match.path,
    newBillingInformation,
    profile.data.billing.country,
    profile.data.billing.name,
    profile.data.billing.postcode,
    profile.data.billing.street1,
    profile.data.billing.type,
  ]);

  if (location.pathname === EDUCATION_PURCHASE_INSTITUTION) {
    cta = (
      <button
        className={classnames('cta-button', {
          disabled: eduInstitutionCtaDisabled,
        })}
        type="submit"
        form="institution-form"
        disabled={eduInstitutionCtaDisabled}
      >
        <span className="ardu-store-button-text">Configure my plan</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === EDUCATION_PURCHASE_PLAN) {
    cta = (
      <button
        className={classnames('cta-button', { busy: progress }, { disabled: eduConfigurePlanDisabled })}
        type="button"
        disabled={eduConfigurePlanDisabled}
        onClick={handlePlanSubmit}
      >
        <span className="ardu-store-button-text">
          {isEduUpgrading ? 'Review payment information' : 'Add billing information'}
        </span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === CERTIFICATION_PURCHASE_EXAM_TYPE) {
    cta = (
      <button
        form="form-certification-type-configuration"
        className={classnames('cta-button', { busy: progress, disabled: certificationType === '' })}
        disabled={certificationType === ''}
        type="submit"
      >
        <span className="ardu-store-button-text">Select Exams</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === CERTIFICATION_PURCHASE_PLAN) {
    cta = (
      <button
        form="certification-configuration-plan"
        className={classnames('cta-button', { busy: progress })}
        type="submit"
      >
        <span className="ardu-store-button-text">Add Billing Information</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === JUNIOR_CERTIFICATION_PURCHASE_PLAN) {
    cta = (
      <button
        form="certification-configuration-plan"
        className={classnames('cta-button', { busy: progress })}
        type="submit"
      >
        <span className="ardu-store-button-text">Add Billing Information</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === CLOUD_PURCHASE_PLAN) {
    cta = (
      <button
        className={classnames(
          'cta-button',
          { busy: progress },
          {
            disabled: cloudPlanDisabled,
          }
        )}
        type="submit"
        disabled={cloudPlanDisabled}
        form="form-cloud-plan-configuration"
      >
        <span className="ardu-store-button-text">
          {isCloudUpgrading ? 'Review payment information' : 'Add billing information'}
        </span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === ENTERPRISE_PURCHASE_PLAN) {
    cta = (
      <button
        className={classnames(
          'cta-button',
          { busy: progress },
          {
            disabled: enterpriseConfigurePlanDisabled,
          }
        )}
        type="submit"
        disabled={enterpriseConfigurePlanDisabled}
        form="form-enterprise-plan"
      >
        <span className="ardu-store-button-text">Continue</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === ENTERPRISE_PURCHASE_ADDONS) {
    cta = (
      <button className={classnames('cta-button', { busy: progress })} type="submit" form="form-enterprise-addons">
        <span className="ardu-store-button-text">Add Billing Information</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (
    location.pathname === EDUCATION_PURCHASE_BILLING ||
    location.pathname === CLOUD_PURCHASE_BILLING ||
    location.pathname === CERTIFICATION_PURCHASE_BILLING ||
    location.pathname === ENTERPRISE_PURCHASE_BILLING ||
    location.pathname === JUNIOR_CERTIFICATION_PURCHASE_BILLING
  ) {
    cta = (
      <button
        className={classnames('cta-button', {
          busy: progress,
          disabled: billingDisabled,
        })}
        type={
          profile.data.billing.name !== '' &&
          profile.data.billing.type !== '' &&
          profile.data.billing.street1 !== '' &&
          profile.data.billing.postcode !== '' &&
          profile.data.billing.country !== ''
            ? 'button'
            : 'submit'
        }
        disabled={billingDisabled}
        form="billing-form"
        onClick={() =>
          !educationPath
            ? profile.data.billing.name !== '' &&
              profile.data.billing.type !== '' &&
              profile.data.billing.street1 !== '' &&
              profile.data.billing.postcode !== '' &&
              profile.data.billing.country !== ''
              ? history.push(`${match.path}/payment`)
              : setNewBillingInformation(true)
            : profile.data.billing.name === '' ||
              profile.data.billing.type === '' ||
              profile.data.billing.street1 === '' ||
              profile.data.billing.postcode === '' ||
              profile.data.billing.country === ''
            ? sessionStorage.setItem('BIerrors', true)
            : history.push(`${match.path}/payment`)
        }
      >
        <span className="ardu-store-button-text">Add payment information</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (
    location.pathname === CLOUD_PURCHASE_PAYMENT ||
    location.pathname === CERTIFICATION_PURCHASE_PAYMENT ||
    location.pathname === JUNIOR_CERTIFICATION_PURCHASE_PAYMENT
  ) {
    cta = (
      <button
        className={classnames('cta-button', {
          busy: paymentProgress,
          disabled:
            purchaseInProgress ||
            newCard ||
            !terms ||
            (newCard && coupon.data === null) ||
            ((paymentProgress || !terms) && !fullValidCoupon),
        })}
        disabled={
          purchaseInProgress || (newCard && coupon.data === null) || ((paymentProgress || !terms) && !fullValidCoupon)
        }
        type="submit"
        form="payment-form"
      >
        <span className="ardu-store-button-text">{isCertification ? 'Purchase' : 'Purchase Plan'}</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === EDUCATION_PURCHASE_PAYMENT) {
    cta = (
      <button
        className={classnames('cta-button', {
          busy: paymentProgress,
          disabled:
            purchaseInProgress ||
            (!coupon.data?.valid && card.list.length === 0) ||
            (coupon.data?.valid && card.list.length === 0 && eduTodayPrice > 0) ||
            (newCard && coupon.data === null) ||
            ((paymentProgress || !terms) && !fullValidCoupon),
        })}
        disabled={
          purchaseInProgress ||
          (!coupon.data?.valid && card.list.length === 0) ||
          (coupon.data?.valid && card.list.length === 0 && eduTodayPrice > 0) ||
          (newCard && coupon.data === null) ||
          ((paymentProgress || !terms) && !fullValidCoupon)
        }
        type="submit"
        form="payment-form"
      >
        <span className="ardu-store-button-text">{isCertification ? 'Purchase' : 'Purchase Plan'}</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (location.pathname === ENTERPRISE_PURCHASE_PAYMENT) {
    cta = (
      <button
        className={classnames('cta-button', {
          busy: paymentProgress,
          disabled:
            purchaseInProgress ||
            (!coupon.data?.valid && card.list.length === 0) ||
            (coupon.data?.valid && card.list.length === 0 && enterpriseTodayPrice > 0) ||
            (newCard && coupon.data === null) ||
            ((paymentProgress || !terms) && !fullValidCoupon),
        })}
        disabled={
          purchaseInProgress ||
          (!coupon.data?.valid && card.list.length === 0) ||
          (coupon.data?.valid && card.list.length === 0 && enterpriseTodayPrice > 0) ||
          (newCard && coupon.data === null) ||
          ((paymentProgress || !terms) && !fullValidCoupon)
        }
        type="submit"
        form="payment-form"
      >
        <span className="ardu-store-button-text">{isCertification ? 'Purchase' : 'Purchase Plan'}</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (
    location.pathname === ENTERPRISE_PURCHASE_ALTERNATIVE_PAYMENT ||
    location.pathname === EDUCATION_PURCHASE_ALTERNATIVE_PAYMENT
  ) {
    cta = (
      <button
        className={classnames('cta-button', {
          busy: paymentProgress,
          disabled: purchaseInProgress || paymentProgress || !terms,
        })}
        disabled={purchaseInProgress || paymentProgress || !terms}
        type="button"
        form="alternative-payment-form"
        onClick={handleAlternativePaymentSubmit}
      >
        <span className="ardu-store-button-text">{'Submit request'}</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else if (
    location.pathname === EDUCATION_PURCHASE_PAYMENT_INFO ||
    location.pathname === CLOUD_PURCHASE_PAYMENT_INFO
  ) {
    cta = (
      <button
        form="payment-info-form"
        className={classnames('cta-button', { busy: props.actionInProgress, disabled: paymentInfoDisabled })}
        disabled={paymentInfoDisabled}
        type="submit"
      >
        <span className="ardu-store-button-text">{'Update my Plan'}</span>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </button>
    );
  } else {
    cta = null;
  }

  return cta;
}

SummaryCtaButtons.propTypes = {
  match: PropTypes.object,
  coupon: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.object,
  card: PropTypes.object,
  cart: PropTypes.object,
  eduInstitutionCtaDisabled: PropTypes.bool,
  eduConfigurePlanDisabled: PropTypes.bool,
  enterpriseConfigurePlanDisabled: PropTypes.bool,
  billingDisabled: PropTypes.bool,
  isEduUpgrading: PropTypes.bool,
  progress: PropTypes.bool,
  handlePlanSubmit: PropTypes.func,
  paymentProgress: PropTypes.bool,
  terms: PropTypes.bool,
  fullValidCoupon: PropTypes.bool,
  isCloudUpgrading: PropTypes.bool,
  cloudPlanDisabled: PropTypes.bool,
  newCard: PropTypes.bool,
  enterpriseTodayPrice: PropTypes.number,
  purchaseInProgress: PropTypes.bool,
  educationPath: PropTypes.bool,
  eduTodayPrice: PropTypes.number,
  handleAlternativePaymentSubmit: PropTypes.func,
  certificationType: PropTypes.string,
};

export default SummaryCtaButtons;
